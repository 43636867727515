import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8d462bf6 = () => interopDefault(import('../pages/BlankPage.vue' /* webpackChunkName: "pages/BlankPage" */))
const _49450b58 = () => interopDefault(import('../pages/Index.vue' /* webpackChunkName: "pages/Index" */))
const _7e923403 = () => interopDefault(import('../pages/about/Contacts.vue' /* webpackChunkName: "pages/about/Contacts" */))
const _040d325c = () => interopDefault(import('../pages/about/Index.vue' /* webpackChunkName: "pages/about/Index" */))
const _75982a83 = () => interopDefault(import('../pages/about/News.vue' /* webpackChunkName: "pages/about/News" */))
const _722b07ce = () => interopDefault(import('../pages/about/NewsAll.vue' /* webpackChunkName: "pages/about/NewsAll" */))
const _ba5df80a = () => interopDefault(import('../pages/about/Vacancy.vue' /* webpackChunkName: "pages/about/Vacancy" */))
const _69cad3f0 = () => interopDefault(import('../pages/auth/Login.vue' /* webpackChunkName: "pages/auth/Login" */))
const _1ca0b293 = () => interopDefault(import('../pages/auth/Logout.vue' /* webpackChunkName: "pages/auth/Logout" */))
const _b8d384fc = () => interopDefault(import('../pages/auth/Registration.vue' /* webpackChunkName: "pages/auth/Registration" */))
const _102cf45b = () => interopDefault(import('../pages/auth/RegistrationComplete.vue' /* webpackChunkName: "pages/auth/RegistrationComplete" */))
const _53ba7910 = () => interopDefault(import('../pages/auth/RestorePassword.vue' /* webpackChunkName: "pages/auth/RestorePassword" */))
const _023d41bc = () => interopDefault(import('../pages/auth/SetNewPassword.vue' /* webpackChunkName: "pages/auth/SetNewPassword" */))
const _a4df6c12 = () => interopDefault(import('../pages/basket/Index.vue' /* webpackChunkName: "pages/basket/Index" */))
const _a81d220a = () => interopDefault(import('../pages/order/Distributors.vue' /* webpackChunkName: "pages/order/Distributors" */))
const _958be21c = () => interopDefault(import('../pages/order/HowToOrder.vue' /* webpackChunkName: "pages/order/HowToOrder" */))
const _62cfdb8e = () => interopDefault(import('../pages/order/PayMethods.vue' /* webpackChunkName: "pages/order/PayMethods" */))
const _2e9489ff = () => interopDefault(import('../pages/order/Return.vue' /* webpackChunkName: "pages/order/Return" */))
const _6da656ae = () => interopDefault(import('../pages/order/SampleRequirements.vue' /* webpackChunkName: "pages/order/SampleRequirements" */))
const _5d05ec86 = () => interopDefault(import('../pages/order/Shipping.vue' /* webpackChunkName: "pages/order/Shipping" */))
const _4af03c31 = () => interopDefault(import('../pages/order/SpecialOffers.vue' /* webpackChunkName: "pages/order/SpecialOffers" */))
const _29f81e0c = () => interopDefault(import('../pages/payment/payment-redirect.vue' /* webpackChunkName: "pages/payment/payment-redirect" */))
const _19f21ff2 = () => interopDefault(import('../pages/payment/SberbankPayCompleted.vue' /* webpackChunkName: "pages/payment/SberbankPayCompleted" */))
const _86f0433a = () => interopDefault(import('../pages/policy/Index.vue' /* webpackChunkName: "pages/policy/Index" */))
const _d8aac2d6 = () => interopDefault(import('../pages/products/Index.vue' /* webpackChunkName: "pages/products/Index" */))
const _a71541de = () => interopDefault(import('../pages/products/Order.vue' /* webpackChunkName: "pages/products/Order" */))
const _fa38a9ac = () => interopDefault(import('../pages/products/Pricelist.vue' /* webpackChunkName: "pages/products/Pricelist" */))
const _739faf84 = () => interopDefault(import('../pages/profile/Addresses.vue' /* webpackChunkName: "pages/profile/Addresses" */))
const _6cc0a5e7 = () => interopDefault(import('../pages/profile/Contacts.vue' /* webpackChunkName: "pages/profile/Contacts" */))
const _404b93d5 = () => interopDefault(import('../pages/profile/Discount.vue' /* webpackChunkName: "pages/profile/Discount" */))
const _07024124 = () => interopDefault(import('../pages/profile/Index.vue' /* webpackChunkName: "pages/profile/Index" */))
const _d0d4030e = () => interopDefault(import('../pages/profile/Orders.vue' /* webpackChunkName: "pages/profile/Orders" */))
const _ace1f94e = () => interopDefault(import('../pages/search/Index.vue' /* webpackChunkName: "pages/search/Index" */))
const _0ba03d5d = () => interopDefault(import('../pages/sequence/Header.vue' /* webpackChunkName: "pages/sequence/Header" */))
const _0426969c = () => interopDefault(import('../pages/sequence/Index.vue' /* webpackChunkName: "pages/sequence/Index" */))
const _09db2ade = () => interopDefault(import('../pages/sequence/SequenceEditor.vue' /* webpackChunkName: "pages/sequence/SequenceEditor" */))
const _95d625e2 = () => interopDefault(import('../pages/services/Index.vue' /* webpackChunkName: "pages/services/Index" */))
const _312e2ca4 = () => interopDefault(import('../pages/services/Pricelist.vue' /* webpackChunkName: "pages/services/Pricelist" */))
const _487c09fc = () => interopDefault(import('../pages/sout/Evrogen.vue' /* webpackChunkName: "pages/sout/Evrogen" */))
const _3b292f06 = () => interopDefault(import('../pages/sout/Ip.vue' /* webpackChunkName: "pages/sout/Ip" */))
const _1a508db2 = () => interopDefault(import('../pages/sout/Lab.vue' /* webpackChunkName: "pages/sout/Lab" */))
const _1be508ce = () => interopDefault(import('../pages/sout/Ru.vue' /* webpackChunkName: "pages/sout/Ru" */))
const _3170de82 = () => interopDefault(import('../pages/support/Citing.vue' /* webpackChunkName: "pages/support/Citing" */))
const _3e59de14 = () => interopDefault(import('../pages/support/Index.vue' /* webpackChunkName: "pages/support/Index" */))
const _b7970672 = () => interopDefault(import('../pages/support/LicenseStatements.vue' /* webpackChunkName: "pages/support/LicenseStatements" */))
const _5b6275ff = () => interopDefault(import('../pages/support/Literature.vue' /* webpackChunkName: "pages/support/Literature" */))
const _72fccbf5 = () => interopDefault(import('../pages/support/Publications.vue' /* webpackChunkName: "pages/support/Publications" */))
const _21de4158 = () => interopDefault(import('../pages/support/Technologies.vue' /* webpackChunkName: "pages/support/Technologies" */))
const _15477015 = () => interopDefault(import('../pages/products/antibodies/AB-GFP.vue' /* webpackChunkName: "pages/products/antibodies/AB-GFP" */))
const _3d2a066a = () => interopDefault(import('../pages/products/antibodies/Anti-KillerRed.vue' /* webpackChunkName: "pages/products/antibodies/Anti-KillerRed" */))
const _61cc7df0 = () => interopDefault(import('../pages/products/antibodies/Anti-TagCGYFP.vue' /* webpackChunkName: "pages/products/antibodies/Anti-TagCGYFP" */))
const _dd54965a = () => interopDefault(import('../pages/products/antibodies/Anti-tRFP.vue' /* webpackChunkName: "pages/products/antibodies/Anti-tRFP" */))
const _4c10fd96 = () => interopDefault(import('../pages/products/antibodies/Anti-TurboGFPd.vue' /* webpackChunkName: "pages/products/antibodies/Anti-TurboGFPd" */))
const _9b6a7560 = () => interopDefault(import('../pages/products/antibodies/Anti-TurboYFP.vue' /* webpackChunkName: "pages/products/antibodies/Anti-TurboYFP" */))
const _a056fd38 = () => interopDefault(import('../pages/products/antibodies/Citing.vue' /* webpackChunkName: "pages/products/antibodies/Citing" */))
const _216556f2 = () => interopDefault(import('../pages/products/antibodies/Index.vue' /* webpackChunkName: "pages/products/antibodies/Index" */))
const _cc162338 = () => interopDefault(import('../pages/products/cdna/Index.vue' /* webpackChunkName: "pages/products/cdna/Index" */))
const _42d7b6a0 = () => interopDefault(import('../pages/products/cloning/Index.vue' /* webpackChunkName: "pages/products/cloning/Index" */))
const _334164f0 = () => interopDefault(import('../pages/products/cloning/QuickTa.vue' /* webpackChunkName: "pages/products/cloning/QuickTa" */))
const _033dde44 = () => interopDefault(import('../pages/products/cloning/Vectors.vue' /* webpackChunkName: "pages/products/cloning/Vectors" */))
const _a9448534 = () => interopDefault(import('../pages/products/components/Index.vue' /* webpackChunkName: "pages/products/components/Index" */))
const _04e422a0 = () => interopDefault(import('../pages/products/evaluation/DnaLadders.vue' /* webpackChunkName: "pages/products/evaluation/DnaLadders" */))
const _42da6be8 = () => interopDefault(import('../pages/products/evaluation/Index.vue' /* webpackChunkName: "pages/products/evaluation/Index" */))
const _573a5b1a = () => interopDefault(import('../pages/products/fp/Index.vue' /* webpackChunkName: "pages/products/fp/Index" */))
const _4c9365af = () => interopDefault(import('../pages/products/isolation/BisQuick.vue' /* webpackChunkName: "pages/products/isolation/BisQuick" */))
const _3b06bdc2 = () => interopDefault(import('../pages/products/isolation/Citing.vue' /* webpackChunkName: "pages/products/isolation/Citing" */))
const _7596ba8a = () => interopDefault(import('../pages/products/isolation/CleanRna.vue' /* webpackChunkName: "pages/products/isolation/CleanRna" */))
const _2f069926 = () => interopDefault(import('../pages/products/isolation/Cleanup.vue' /* webpackChunkName: "pages/products/isolation/Cleanup" */))
const _84565152 = () => interopDefault(import('../pages/products/isolation/CleanupStGel.vue' /* webpackChunkName: "pages/products/isolation/CleanupStGel" */))
const _5e79c468 = () => interopDefault(import('../pages/products/isolation/CleanupStPCR.vue' /* webpackChunkName: "pages/products/isolation/CleanupStPCR" */))
const _4bffc5ac = () => interopDefault(import('../pages/products/isolation/DNAseE.vue' /* webpackChunkName: "pages/products/isolation/DNAseE" */))
const _64c7e020 = () => interopDefault(import('../pages/products/isolation/ExtractDnaBloodAndCells.vue' /* webpackChunkName: "pages/products/isolation/ExtractDnaBloodAndCells" */))
const _373c2099 = () => interopDefault(import('../pages/products/isolation/ExtractDnaFfpe.vue' /* webpackChunkName: "pages/products/isolation/ExtractDnaFfpe" */))
const _3459b05c = () => interopDefault(import('../pages/products/isolation/ExtractRna.vue' /* webpackChunkName: "pages/products/isolation/ExtractRna" */))
const _46eb16d4 = () => interopDefault(import('../pages/products/isolation/Index.vue' /* webpackChunkName: "pages/products/isolation/Index" */))
const _59c23164 = () => interopDefault(import('../pages/products/isolation/IntactRna.vue' /* webpackChunkName: "pages/products/isolation/IntactRna" */))
const _232c83d9 = () => interopDefault(import('../pages/products/isolation/PlasmidKits.vue' /* webpackChunkName: "pages/products/isolation/PlasmidKits" */))
const _9f37354e = () => interopDefault(import('../pages/products/isolation/RCF-RPM.vue' /* webpackChunkName: "pages/products/isolation/RCF-RPM" */))
const _16e9c163 = () => interopDefault(import('../pages/products/isolation/RiboCare.vue' /* webpackChunkName: "pages/products/isolation/RiboCare" */))
const _354372b4 = () => interopDefault(import('../pages/products/isolation/RNASolo.vue' /* webpackChunkName: "pages/products/isolation/RNASolo" */))
const _7d4f29d0 = () => interopDefault(import('../pages/products/lenti/Index.vue' /* webpackChunkName: "pages/products/lenti/Index" */))
const _62fe27aa = () => interopDefault(import('../pages/products/mycoplasma/Index.vue' /* webpackChunkName: "pages/products/mycoplasma/Index" */))
const _230b789b = () => interopDefault(import('../pages/products/mycoplasma/MycoRealTime.vue' /* webpackChunkName: "pages/products/mycoplasma/MycoRealTime" */))
const _12f8cdc4 = () => interopDefault(import('../pages/products/mycoplasma/MycoReport.vue' /* webpackChunkName: "pages/products/mycoplasma/MycoReport" */))
const _8d709ab2 = () => interopDefault(import('../pages/products/nomotech/BRCA.vue' /* webpackChunkName: "pages/products/nomotech/BRCA" */))
const _e83821ce = () => interopDefault(import('../pages/products/nomotech/CES1.vue' /* webpackChunkName: "pages/products/nomotech/CES1" */))
const _5f04f62c = () => interopDefault(import('../pages/products/nomotech/CYP2C19.vue' /* webpackChunkName: "pages/products/nomotech/CYP2C19" */))
const _fa7e0eb2 = () => interopDefault(import('../pages/products/nomotech/CYP2C9-VKORC1-CYP4F2.vue' /* webpackChunkName: "pages/products/nomotech/CYP2C9-VKORC1-CYP4F2" */))
const _7b8685da = () => interopDefault(import('../pages/products/nomotech/ExtractDNA2.vue' /* webpackChunkName: "pages/products/nomotech/ExtractDNA2" */))
const _0072ea46 = () => interopDefault(import('../pages/products/nomotech/ExtractDnaFfpeNomotech.vue' /* webpackChunkName: "pages/products/nomotech/ExtractDnaFfpeNomotech" */))
const _419bd5c7 = () => interopDefault(import('../pages/products/nomotech/F2-F5.vue' /* webpackChunkName: "pages/products/nomotech/F2-F5" */))
const _258677ee = () => interopDefault(import('../pages/products/nomotech/Index.vue' /* webpackChunkName: "pages/products/nomotech/Index" */))
const _1c28ad6d = () => interopDefault(import('../pages/products/nomotech/Insider.vue' /* webpackChunkName: "pages/products/nomotech/Insider" */))
const _bc50f64a = () => interopDefault(import('../pages/products/nomotech/InsiderEGFR.vue' /* webpackChunkName: "pages/products/nomotech/InsiderEGFR" */))
const _0ca2c298 = () => interopDefault(import('../pages/products/nomotech/InsiderFullRas.vue' /* webpackChunkName: "pages/products/nomotech/InsiderFullRas" */))
const _07a76d8c = () => interopDefault(import('../pages/products/nomotech/MGMT.vue' /* webpackChunkName: "pages/products/nomotech/MGMT" */))
const _30badfca = () => interopDefault(import('../pages/products/nomotech/NAT2.vue' /* webpackChunkName: "pages/products/nomotech/NAT2" */))
const _5877e092 = () => interopDefault(import('../pages/products/nomotech/SLCO1B1.vue' /* webpackChunkName: "pages/products/nomotech/SLCO1B1" */))
const _15d2a836 = () => interopDefault(import('../pages/products/pcr/Index.vue' /* webpackChunkName: "pages/products/pcr/Index" */))
const _78e9a0f0 = () => interopDefault(import('../pages/products/practicum/Index.vue' /* webpackChunkName: "pages/products/practicum/Index" */))
const _5343f05e = () => interopDefault(import('../pages/products/practicum/Topics.vue' /* webpackChunkName: "pages/products/practicum/Topics" */))
const _210699b8 = () => interopDefault(import('../pages/services/chemistry/Index.vue' /* webpackChunkName: "pages/services/chemistry/Index" */))
const _084773a0 = () => interopDefault(import('../pages/services/chemistry/Order.vue' /* webpackChunkName: "pages/services/chemistry/Order" */))
const _35e41f56 = () => interopDefault(import('../pages/services/ges/Index.vue' /* webpackChunkName: "pages/services/ges/Index" */))
const _044e9e5e = () => interopDefault(import('../pages/services/ges/Order.vue' /* webpackChunkName: "pages/services/ges/Order" */))
const _b2affafc = () => interopDefault(import('../pages/services/synthesis/Citing.vue' /* webpackChunkName: "pages/services/synthesis/Citing" */))
const _73ae3a14 = () => interopDefault(import('../pages/services/synthesis/Index.vue' /* webpackChunkName: "pages/services/synthesis/Index" */))
const _4b62794e = () => interopDefault(import('../pages/services/synthesis/Kinination.vue' /* webpackChunkName: "pages/services/synthesis/Kinination" */))
const _e70e0ae0 = () => interopDefault(import('../pages/services/synthesis/Order.vue' /* webpackChunkName: "pages/services/synthesis/Order" */))
const _bcdaa518 = () => interopDefault(import('../pages/services/synthesis/OrderForm.vue' /* webpackChunkName: "pages/services/synthesis/OrderForm" */))
const _628914da = () => interopDefault(import('../pages/support/license-statements/EvrogenDsnLicense.vue' /* webpackChunkName: "pages/support/license-statements/EvrogenDsnLicense" */))
const _68583196 = () => interopDefault(import('../pages/support/license-statements/EvrogenFpLicense.vue' /* webpackChunkName: "pages/support/license-statements/EvrogenFpLicense" */))
const _5bf27295 = () => interopDefault(import('../pages/support/license-statements/InvitrogenGatewayLicense.vue' /* webpackChunkName: "pages/support/license-statements/InvitrogenGatewayLicense" */))
const _00884484 = () => interopDefault(import('../pages/support/technologies/BloodPreparation.vue' /* webpackChunkName: "pages/support/technologies/BloodPreparation" */))
const _54cea44b = () => interopDefault(import('../pages/support/technologies/GenomeWalking.vue' /* webpackChunkName: "pages/support/technologies/GenomeWalking" */))
const _62b53b2c = () => interopDefault(import('../pages/support/technologies/LongProductAmplification.vue' /* webpackChunkName: "pages/support/technologies/LongProductAmplification" */))
const _61839867 = () => interopDefault(import('../pages/support/technologies/MintcDNASynthesis.vue' /* webpackChunkName: "pages/support/technologies/MintcDNASynthesis" */))
const _3d2b349a = () => interopDefault(import('../pages/support/technologies/MOS.vue' /* webpackChunkName: "pages/support/technologies/MOS" */))
const _bc3cd034 = () => interopDefault(import('../pages/support/technologies/Normalization.vue' /* webpackChunkName: "pages/support/technologies/Normalization" */))
const _4ef9b5f4 = () => interopDefault(import('../pages/support/technologies/PlasmidPurification.vue' /* webpackChunkName: "pages/support/technologies/PlasmidPurification" */))
const _f02c573e = () => interopDefault(import('../pages/support/technologies/RnaElectrophoresis.vue' /* webpackChunkName: "pages/support/technologies/RnaElectrophoresis" */))
const _4580b720 = () => interopDefault(import('../pages/support/technologies/RnaIsolation.vue' /* webpackChunkName: "pages/support/technologies/RnaIsolation" */))
const _c4da19dc = () => interopDefault(import('../pages/support/technologies/Smart.vue' /* webpackChunkName: "pages/support/technologies/Smart" */))
const _8db207de = () => interopDefault(import('../pages/support/technologies/Ssh.vue' /* webpackChunkName: "pages/support/technologies/Ssh" */))
const _7c358a5c = () => interopDefault(import('../pages/support/technologies/StepOutRace.vue' /* webpackChunkName: "pages/support/technologies/StepOutRace" */))
const _3bb8b201 = () => interopDefault(import('../pages/support/technologies/WtbPcr.vue' /* webpackChunkName: "pages/support/technologies/WtbPcr" */))
const _08a3e2f2 = () => interopDefault(import('../pages/products/cdna/normalization/Index.vue' /* webpackChunkName: "pages/products/cdna/normalization/Index" */))
const _dd34d69c = () => interopDefault(import('../pages/products/cdna/race/Index.vue' /* webpackChunkName: "pages/products/cdna/race/Index" */))
const _891942d2 = () => interopDefault(import('../pages/products/cdna/race/MintRaceCdna.vue' /* webpackChunkName: "pages/products/cdna/race/MintRaceCdna" */))
const _11f5ab8d = () => interopDefault(import('../pages/products/cdna/race/MintRacePrimers.vue' /* webpackChunkName: "pages/products/cdna/race/MintRacePrimers" */))
const _15305529 = () => interopDefault(import('../pages/products/cdna/synthesis/Index.vue' /* webpackChunkName: "pages/products/cdna/synthesis/Index" */))
const _2661b6dc = () => interopDefault(import('../pages/products/cdna/synthesis/Magnus.vue' /* webpackChunkName: "pages/products/cdna/synthesis/Magnus" */))
const _2d98cf5b = () => interopDefault(import('../pages/products/cdna/synthesis/Mint.vue' /* webpackChunkName: "pages/products/cdna/synthesis/Mint" */))
const _43143f8f = () => interopDefault(import('../pages/products/cdna/synthesis/MintCiting.vue' /* webpackChunkName: "pages/products/cdna/synthesis/MintCiting" */))
const _28bece03 = () => interopDefault(import('../pages/products/cdna/synthesis/MMLV.vue' /* webpackChunkName: "pages/products/cdna/synthesis/MMLV" */))
const _974abf92 = () => interopDefault(import('../pages/products/cdna/synthesis/MMLVCiting.vue' /* webpackChunkName: "pages/products/cdna/synthesis/MMLVCiting" */))
const _6f161aa1 = () => interopDefault(import('../pages/products/cdna/synthesis/OneTube.vue' /* webpackChunkName: "pages/products/cdna/synthesis/OneTube" */))
const _c5bd0356 = () => interopDefault(import('../pages/products/cdna/synthesis/OneTubeCiting.vue' /* webpackChunkName: "pages/products/cdna/synthesis/OneTubeCiting" */))
const _0724fe94 = () => interopDefault(import('../pages/products/cloning/competent-cells/Citing.vue' /* webpackChunkName: "pages/products/cloning/competent-cells/Citing" */))
const _2c7873c2 = () => interopDefault(import('../pages/products/cloning/competent-cells/Index.vue' /* webpackChunkName: "pages/products/cloning/competent-cells/Index" */))
const _2f140233 = () => interopDefault(import('../pages/products/evaluation/quantum/Concentration.vue' /* webpackChunkName: "pages/products/evaluation/quantum/Concentration" */))
const _572a0c6b = () => interopDefault(import('../pages/products/evaluation/quantum/Fragmentation.vue' /* webpackChunkName: "pages/products/evaluation/quantum/Fragmentation" */))
const _1f0ecbd0 = () => interopDefault(import('../pages/products/evaluation/quantum/Index.vue' /* webpackChunkName: "pages/products/evaluation/quantum/Index" */))
const _75463679 = () => interopDefault(import('../pages/products/fp/basic/Index.vue' /* webpackChunkName: "pages/products/fp/basic/Index" */))
const _025b57a0 = () => interopDefault(import('../pages/products/fp/basic/Previous.vue' /* webpackChunkName: "pages/products/fp/basic/Previous" */))
const _083ac0dc = () => interopDefault(import('../pages/products/fp/biosensors/Index.vue' /* webpackChunkName: "pages/products/fp/biosensors/Index" */))
const _b521d9f0 = () => interopDefault(import('../pages/products/fp/biosensors/ProductsList.vue' /* webpackChunkName: "pages/products/fp/biosensors/ProductsList" */))
const _77aa5ed2 = () => interopDefault(import('../pages/products/fp/photosensibilizators/Index.vue' /* webpackChunkName: "pages/products/fp/photosensibilizators/Index" */))
const _93f4175c = () => interopDefault(import('../pages/products/fp/photosensibilizators/ProductsList.vue' /* webpackChunkName: "pages/products/fp/photosensibilizators/ProductsList" */))
const _34e858b0 = () => interopDefault(import('../pages/products/fp/recombinants/Index.vue' /* webpackChunkName: "pages/products/fp/recombinants/Index" */))
const _30db394a = () => interopDefault(import('../pages/products/fp/vectors/Index.vue' /* webpackChunkName: "pages/products/fp/vectors/Index" */))
const _a98bdff0 = () => interopDefault(import('../pages/products/fp/vectors/OrganelleLabeling.vue' /* webpackChunkName: "pages/products/fp/vectors/OrganelleLabeling" */))
const _10353f72 = () => interopDefault(import('../pages/products/fp/vectors/PromoterTesting.vue' /* webpackChunkName: "pages/products/fp/vectors/PromoterTesting" */))
const _d23ae0ca = () => interopDefault(import('../pages/products/isolation/cleanmag/Index.vue' /* webpackChunkName: "pages/products/isolation/cleanmag/Index" */))
const _fbf1c6ec = () => interopDefault(import('../pages/products/isolation/cleanmag/MagStand.vue' /* webpackChunkName: "pages/products/isolation/cleanmag/MagStand" */))
const _ac23ee5c = () => interopDefault(import('../pages/products/pcr/bulk/Index.vue' /* webpackChunkName: "pages/products/pcr/bulk/Index" */))
const _da6a4394 = () => interopDefault(import('../pages/products/pcr/mixes/Citing.vue' /* webpackChunkName: "pages/products/pcr/mixes/Citing" */))
const _32b1d040 = () => interopDefault(import('../pages/products/pcr/mixes/Index.vue' /* webpackChunkName: "pages/products/pcr/mixes/Index" */))
const _65b007c4 = () => interopDefault(import('../pages/products/pcr/mixes/KtnMixHs.vue' /* webpackChunkName: "pages/products/pcr/mixes/KtnMixHs" */))
const _394a31b2 = () => interopDefault(import('../pages/products/pcr/mixes/qPcrMixHs.vue' /* webpackChunkName: "pages/products/pcr/mixes/qPcrMixHs" */))
const _13bf634a = () => interopDefault(import('../pages/products/pcr/mixes/qPcrMixHsCiting.vue' /* webpackChunkName: "pages/products/pcr/mixes/qPcrMixHsCiting" */))
const _180a2838 = () => interopDefault(import('../pages/products/pcr/mixes/qPcrMixHsRox.vue' /* webpackChunkName: "pages/products/pcr/mixes/qPcrMixHsRox" */))
const _3b560c06 = () => interopDefault(import('../pages/products/pcr/mixes/qPcrMixHsSybr.vue' /* webpackChunkName: "pages/products/pcr/mixes/qPcrMixHsSybr" */))
const _395e3d64 = () => interopDefault(import('../pages/products/pcr/mixes/qPcrMixHsSybrRox.vue' /* webpackChunkName: "pages/products/pcr/mixes/qPcrMixHsSybrRox" */))
const _7ef8bf41 = () => interopDefault(import('../pages/products/pcr/mixes/qPcrMixHsUdg.vue' /* webpackChunkName: "pages/products/pcr/mixes/qPcrMixHsUdg" */))
const _1795db5e = () => interopDefault(import('../pages/products/pcr/mixes/ScreenMix.vue' /* webpackChunkName: "pages/products/pcr/mixes/ScreenMix" */))
const _94bf84ee = () => interopDefault(import('../pages/products/pcr/mixes/ScreenMixHs.vue' /* webpackChunkName: "pages/products/pcr/mixes/ScreenMixHs" */))
const _1c4f94c2 = () => interopDefault(import('../pages/products/pcr/mixes/ScreenMixHsUdg.vue' /* webpackChunkName: "pages/products/pcr/mixes/ScreenMixHsUdg" */))
const _63b63412 = () => interopDefault(import('../pages/products/pcr/polymerases/Citing.vue' /* webpackChunkName: "pages/products/pcr/polymerases/Citing" */))
const _eb68d2f8 = () => interopDefault(import('../pages/products/pcr/polymerases/Index.vue' /* webpackChunkName: "pages/products/pcr/polymerases/Index" */))
const _9012cbf2 = () => interopDefault(import('../pages/services/chemistry/organic-synthesis/Index.vue' /* webpackChunkName: "pages/services/chemistry/organic-synthesis/Index" */))
const _bac97480 = () => interopDefault(import('../pages/services/chemistry/semi-industrial-technologies/Index.vue' /* webpackChunkName: "pages/services/chemistry/semi-industrial-technologies/Index" */))
const _0cef3f7e = () => interopDefault(import('../pages/services/ges/amplicon-cloning/Index.vue' /* webpackChunkName: "pages/services/ges/amplicon-cloning/Index" */))
const _a5ce43ac = () => interopDefault(import('../pages/services/ges/gene-synthesis/Citing.vue' /* webpackChunkName: "pages/services/ges/gene-synthesis/Citing" */))
const _635f496c = () => interopDefault(import('../pages/services/ges/gene-synthesis/Index.vue' /* webpackChunkName: "pages/services/ges/gene-synthesis/Index" */))
const _76d28306 = () => interopDefault(import('../pages/services/ges/mutagenesis/Citing.vue' /* webpackChunkName: "pages/services/ges/mutagenesis/Citing" */))
const _094a92b9 = () => interopDefault(import('../pages/services/ges/mutagenesis/Index.vue' /* webpackChunkName: "pages/services/ges/mutagenesis/Index" */))
const _6a5c6172 = () => interopDefault(import('../pages/services/ges/pcr-cloning/Index.vue' /* webpackChunkName: "pages/services/ges/pcr-cloning/Index" */))
const _85a4ffde = () => interopDefault(import('../pages/services/sequencing/sanger/Citing.vue' /* webpackChunkName: "pages/services/sequencing/sanger/Citing" */))
const _00cb4ea5 = () => interopDefault(import('../pages/services/sequencing/sanger/Index.vue' /* webpackChunkName: "pages/services/sequencing/sanger/Index" */))
const _19960f21 = () => interopDefault(import('../pages/services/sequencing/sanger/Order.vue' /* webpackChunkName: "pages/services/sequencing/sanger/Order" */))
const _101e428d = () => interopDefault(import('../pages/services/sequencing/sanger/Requirements.vue' /* webpackChunkName: "pages/services/sequencing/sanger/Requirements" */))
const _06fe8f16 = () => interopDefault(import('../pages/services/sequencing/sanger/StandardPrimers.vue' /* webpackChunkName: "pages/services/sequencing/sanger/StandardPrimers" */))
const _2793d098 = () => interopDefault(import('../pages/products/cdna/normalization/dsn/Citing.vue' /* webpackChunkName: "pages/products/cdna/normalization/dsn/Citing" */))
const _024fb6a2 = () => interopDefault(import('../pages/products/cdna/normalization/dsn/Index.vue' /* webpackChunkName: "pages/products/cdna/normalization/dsn/Index" */))
const _f35d7fae = () => interopDefault(import('../pages/products/cdna/normalization/trimmer2/Citing.vue' /* webpackChunkName: "pages/products/cdna/normalization/trimmer2/Citing" */))
const _72a32b8d = () => interopDefault(import('../pages/products/cdna/normalization/trimmer2/Index.vue' /* webpackChunkName: "pages/products/cdna/normalization/trimmer2/Index" */))
const _bffb47d4 = () => interopDefault(import('../pages/products/fp/basic/flim/Index.vue' /* webpackChunkName: "pages/products/fp/basic/flim/Index" */))
const _5a673fc2 = () => interopDefault(import('../pages/products/fp/basic/flim/Properties.vue' /* webpackChunkName: "pages/products/fp/basic/flim/Properties" */))
const _4489bac6 = () => interopDefault(import('../pages/products/fp/basic/photoactivatable/Index.vue' /* webpackChunkName: "pages/products/fp/basic/photoactivatable/Index" */))
const _7e84af6f = () => interopDefault(import('../pages/products/fp/basic/photoactivatable/Properties.vue' /* webpackChunkName: "pages/products/fp/basic/photoactivatable/Properties" */))
const _f76d1640 = () => interopDefault(import('../pages/products/fp/basic/tag-fps/Index.vue' /* webpackChunkName: "pages/products/fp/basic/tag-fps/Index" */))
const _603194d6 = () => interopDefault(import('../pages/products/fp/basic/tag-fps/Properties.vue' /* webpackChunkName: "pages/products/fp/basic/tag-fps/Properties" */))
const _7f06006b = () => interopDefault(import('../pages/products/fp/basic/turbo-colors/Index.vue' /* webpackChunkName: "pages/products/fp/basic/turbo-colors/Index" */))
const _497b28ea = () => interopDefault(import('../pages/products/fp/basic/turbo-colors/Properties.vue' /* webpackChunkName: "pages/products/fp/basic/turbo-colors/Properties" */))
const _e3a3bda2 = () => interopDefault(import('../pages/products/fp/basic/whole-body-imaging/Index.vue' /* webpackChunkName: "pages/products/fp/basic/whole-body-imaging/Index" */))
const _5cdce2a6 = () => interopDefault(import('../pages/products/fp/basic/whole-body-imaging/Properties.vue' /* webpackChunkName: "pages/products/fp/basic/whole-body-imaging/Properties" */))
const _8ab145c8 = () => interopDefault(import('../pages/products/fp/biosensors/case12/Citing.vue' /* webpackChunkName: "pages/products/fp/biosensors/case12/Citing" */))
const _5127b78c = () => interopDefault(import('../pages/products/fp/biosensors/case12/Index.vue' /* webpackChunkName: "pages/products/fp/biosensors/case12/Index" */))
const _ea1b6c00 = () => interopDefault(import('../pages/products/fp/biosensors/casper3-gr/Citing.vue' /* webpackChunkName: "pages/products/fp/biosensors/casper3-gr/Citing" */))
const _7543ea54 = () => interopDefault(import('../pages/products/fp/biosensors/casper3-gr/Index.vue' /* webpackChunkName: "pages/products/fp/biosensors/casper3-gr/Index" */))
const _6027f1bd = () => interopDefault(import('../pages/products/fp/biosensors/hyper/Citing.vue' /* webpackChunkName: "pages/products/fp/biosensors/hyper/Citing" */))
const _6925f779 = () => interopDefault(import('../pages/products/fp/biosensors/hyper/Index.vue' /* webpackChunkName: "pages/products/fp/biosensors/hyper/Index" */))
const _72c7887c = () => interopDefault(import('../pages/products/fp/photosensibilizators/arrest-red/Index.vue' /* webpackChunkName: "pages/products/fp/photosensibilizators/arrest-red/Index" */))
const _5d56bd7a = () => interopDefault(import('../pages/products/fp/photosensibilizators/killer-orange/Citing.vue' /* webpackChunkName: "pages/products/fp/photosensibilizators/killer-orange/Citing" */))
const _43823f33 = () => interopDefault(import('../pages/products/fp/photosensibilizators/killer-orange/Index.vue' /* webpackChunkName: "pages/products/fp/photosensibilizators/killer-orange/Index" */))
const _45929354 = () => interopDefault(import('../pages/products/fp/photosensibilizators/killer-red/Citing.vue' /* webpackChunkName: "pages/products/fp/photosensibilizators/killer-red/Citing" */))
const _58b571fc = () => interopDefault(import('../pages/products/fp/photosensibilizators/killer-red/Index.vue' /* webpackChunkName: "pages/products/fp/photosensibilizators/killer-red/Index" */))
const _9fb2a98c = () => interopDefault(import('../pages/products/pcr/polymerases/encyclo/Citing.vue' /* webpackChunkName: "pages/products/pcr/polymerases/encyclo/Citing" */))
const _2048cb48 = () => interopDefault(import('../pages/products/pcr/polymerases/encyclo/Index.vue' /* webpackChunkName: "pages/products/pcr/polymerases/encyclo/Index" */))
const _80bdb0f8 = () => interopDefault(import('../pages/products/pcr/polymerases/hs-taq/Buffers.vue' /* webpackChunkName: "pages/products/pcr/polymerases/hs-taq/Buffers" */))
const _20d7a793 = () => interopDefault(import('../pages/products/pcr/polymerases/hs-taq/Citing.vue' /* webpackChunkName: "pages/products/pcr/polymerases/hs-taq/Citing" */))
const _6f5d2ee3 = () => interopDefault(import('../pages/products/pcr/polymerases/hs-taq/Index.vue' /* webpackChunkName: "pages/products/pcr/polymerases/hs-taq/Index" */))
const _008c8174 = () => interopDefault(import('../pages/products/pcr/polymerases/ktn-hs/Index.vue' /* webpackChunkName: "pages/products/pcr/polymerases/ktn-hs/Index" */))
const _0f7bdb60 = () => interopDefault(import('../pages/products/pcr/polymerases/rox/Index.vue' /* webpackChunkName: "pages/products/pcr/polymerases/rox/Index" */))
const _7a80eeda = () => interopDefault(import('../pages/products/pcr/polymerases/snp-detect/Citing.vue' /* webpackChunkName: "pages/products/pcr/polymerases/snp-detect/Citing" */))
const _554b3a88 = () => interopDefault(import('../pages/products/pcr/polymerases/snp-detect/Index.vue' /* webpackChunkName: "pages/products/pcr/polymerases/snp-detect/Index" */))
const _11a4d4b7 = () => interopDefault(import('../pages/products/pcr/polymerases/sybr/Index.vue' /* webpackChunkName: "pages/products/pcr/polymerases/sybr/Index" */))
const _575030d9 = () => interopDefault(import('../pages/products/pcr/polymerases/taq/Index.vue' /* webpackChunkName: "pages/products/pcr/polymerases/taq/Index" */))
const _ca8cd7b6 = () => interopDefault(import('../pages/products/pcr/polymerases/tersus/Citing.vue' /* webpackChunkName: "pages/products/pcr/polymerases/tersus/Citing" */))
const _63bb2fde = () => interopDefault(import('../pages/products/pcr/polymerases/tersus/Index.vue' /* webpackChunkName: "pages/products/pcr/polymerases/tersus/Index" */))
const _1e5a03a5 = () => interopDefault(import('../pages/products/fp/basic/flim/bruslee/Index.vue' /* webpackChunkName: "pages/products/fp/basic/flim/bruslee/Index" */))
const _72416dac = () => interopDefault(import('../pages/products/fp/basic/flim/slim/Index.vue' /* webpackChunkName: "pages/products/fp/basic/flim/slim/Index" */))
const _29a8cea6 = () => interopDefault(import('../pages/products/fp/basic/photoactivatable/kfp-red/Citing.vue' /* webpackChunkName: "pages/products/fp/basic/photoactivatable/kfp-red/Citing" */))
const _1d115970 = () => interopDefault(import('../pages/products/fp/basic/photoactivatable/kfp-red/Index.vue' /* webpackChunkName: "pages/products/fp/basic/photoactivatable/kfp-red/Index" */))
const _2317d628 = () => interopDefault(import('../pages/products/fp/basic/photoactivatable/pa-tag-rfp/Index.vue' /* webpackChunkName: "pages/products/fp/basic/photoactivatable/pa-tag-rfp/Index" */))
const _2cc75f30 = () => interopDefault(import('../pages/products/fp/basic/photoactivatable/ps-cfp2/Citing.vue' /* webpackChunkName: "pages/products/fp/basic/photoactivatable/ps-cfp2/Citing" */))
const _56f98fa6 = () => interopDefault(import('../pages/products/fp/basic/photoactivatable/ps-cfp2/Index.vue' /* webpackChunkName: "pages/products/fp/basic/photoactivatable/ps-cfp2/Index" */))
const _38527fc9 = () => interopDefault(import('../pages/products/fp/basic/tag-fps/fusion-red/Citing.vue' /* webpackChunkName: "pages/products/fp/basic/tag-fps/fusion-red/Citing" */))
const _bca91026 = () => interopDefault(import('../pages/products/fp/basic/tag-fps/fusion-red/Index.vue' /* webpackChunkName: "pages/products/fp/basic/tag-fps/fusion-red/Index" */))
const _0b57cdd3 = () => interopDefault(import('../pages/products/fp/basic/tag-fps/mkate2/Citing.vue' /* webpackChunkName: "pages/products/fp/basic/tag-fps/mkate2/Citing" */))
const _3d1f40a3 = () => interopDefault(import('../pages/products/fp/basic/tag-fps/mkate2/Index.vue' /* webpackChunkName: "pages/products/fp/basic/tag-fps/mkate2/Index" */))
const _1dce61e8 = () => interopDefault(import('../pages/products/fp/basic/tag-fps/tag-bfp/Citing.vue' /* webpackChunkName: "pages/products/fp/basic/tag-fps/tag-bfp/Citing" */))
const _550cb44a = () => interopDefault(import('../pages/products/fp/basic/tag-fps/tag-bfp/Index.vue' /* webpackChunkName: "pages/products/fp/basic/tag-fps/tag-bfp/Index" */))
const _5a12ceaa = () => interopDefault(import('../pages/products/fp/basic/tag-fps/tag-cfp/Citing.vue' /* webpackChunkName: "pages/products/fp/basic/tag-fps/tag-cfp/Citing" */))
const _438fbacb = () => interopDefault(import('../pages/products/fp/basic/tag-fps/tag-cfp/Index.vue' /* webpackChunkName: "pages/products/fp/basic/tag-fps/tag-cfp/Index" */))
const _43cbc7dd = () => interopDefault(import('../pages/products/fp/basic/tag-fps/tag-gfp2/Citing.vue' /* webpackChunkName: "pages/products/fp/basic/tag-fps/tag-gfp2/Citing" */))
const _16c2454e = () => interopDefault(import('../pages/products/fp/basic/tag-fps/tag-gfp2/Index.vue' /* webpackChunkName: "pages/products/fp/basic/tag-fps/tag-gfp2/Index" */))
const _0ef568fc = () => interopDefault(import('../pages/products/fp/basic/tag-fps/tag-rfp/Citing.vue' /* webpackChunkName: "pages/products/fp/basic/tag-fps/tag-rfp/Citing" */))
const _3d3d1c5a = () => interopDefault(import('../pages/products/fp/basic/tag-fps/tag-rfp/Index.vue' /* webpackChunkName: "pages/products/fp/basic/tag-fps/tag-rfp/Index" */))
const _3c05ec55 = () => interopDefault(import('../pages/products/fp/basic/tag-fps/tag-yfp/Citing.vue' /* webpackChunkName: "pages/products/fp/basic/tag-fps/tag-yfp/Citing" */))
const _7a5b6c3e = () => interopDefault(import('../pages/products/fp/basic/tag-fps/tag-yfp/Index.vue' /* webpackChunkName: "pages/products/fp/basic/tag-fps/tag-yfp/Index" */))
const _7d7b795b = () => interopDefault(import('../pages/products/fp/basic/turbo-colors/turbo-fp602/Citing.vue' /* webpackChunkName: "pages/products/fp/basic/turbo-colors/turbo-fp602/Citing" */))
const _5994041b = () => interopDefault(import('../pages/products/fp/basic/turbo-colors/turbo-fp602/Index.vue' /* webpackChunkName: "pages/products/fp/basic/turbo-colors/turbo-fp602/Index" */))
const _60197cfb = () => interopDefault(import('../pages/products/fp/basic/turbo-colors/turbo-fp635/Citing.vue' /* webpackChunkName: "pages/products/fp/basic/turbo-colors/turbo-fp635/Citing" */))
const _2f570c7b = () => interopDefault(import('../pages/products/fp/basic/turbo-colors/turbo-fp635/Index.vue' /* webpackChunkName: "pages/products/fp/basic/turbo-colors/turbo-fp635/Index" */))
const _007d1538 = () => interopDefault(import('../pages/products/fp/basic/turbo-colors/turbo-gfp/Citing.vue' /* webpackChunkName: "pages/products/fp/basic/turbo-colors/turbo-gfp/Citing" */))
const _2b9e0ec4 = () => interopDefault(import('../pages/products/fp/basic/turbo-colors/turbo-gfp/Index.vue' /* webpackChunkName: "pages/products/fp/basic/turbo-colors/turbo-gfp/Index" */))
const _95f681e6 = () => interopDefault(import('../pages/products/fp/basic/turbo-colors/turbo-rfp/Citing.vue' /* webpackChunkName: "pages/products/fp/basic/turbo-colors/turbo-rfp/Citing" */))
const _29d24029 = () => interopDefault(import('../pages/products/fp/basic/turbo-colors/turbo-rfp/Index.vue' /* webpackChunkName: "pages/products/fp/basic/turbo-colors/turbo-rfp/Index" */))
const _3bd57b34 = () => interopDefault(import('../pages/products/fp/basic/turbo-colors/turbo-yfp/Citing.vue' /* webpackChunkName: "pages/products/fp/basic/turbo-colors/turbo-yfp/Citing" */))
const _a13124a0 = () => interopDefault(import('../pages/products/fp/basic/turbo-colors/turbo-yfp/Index.vue' /* webpackChunkName: "pages/products/fp/basic/turbo-colors/turbo-yfp/Index" */))
const _05d136bd = () => interopDefault(import('../pages/products/fp/basic/whole-body-imaging/katushka2s/Citing.vue' /* webpackChunkName: "pages/products/fp/basic/whole-body-imaging/katushka2s/Citing" */))
const _76c01279 = () => interopDefault(import('../pages/products/fp/basic/whole-body-imaging/katushka2s/Index.vue' /* webpackChunkName: "pages/products/fp/basic/whole-body-imaging/katushka2s/Index" */))
const _4039fc35 = () => interopDefault(import('../pages/products/fp/basic/whole-body-imaging/nirfp/Citing.vue' /* webpackChunkName: "pages/products/fp/basic/whole-body-imaging/nirfp/Citing" */))
const _bc2683fe = () => interopDefault(import('../pages/products/fp/basic/whole-body-imaging/nirfp/Index.vue' /* webpackChunkName: "pages/products/fp/basic/whole-body-imaging/nirfp/Index" */))
const _e0fce644 = () => interopDefault(import('../pages/products/fp/basic/whole-body-imaging/turbo-fp650/Citing.vue' /* webpackChunkName: "pages/products/fp/basic/whole-body-imaging/turbo-fp650/Citing" */))
const _32e81790 = () => interopDefault(import('../pages/products/fp/basic/whole-body-imaging/turbo-fp650/Index.vue' /* webpackChunkName: "pages/products/fp/basic/whole-body-imaging/turbo-fp650/Index" */))
const _49decfed = () => interopDefault(import('@/layouts/main_page/Index' /* webpackChunkName: "" */))
const _59a7c9bc = () => interopDefault(import('@/pages/Index' /* webpackChunkName: "" */))
const _46183b47 = () => interopDefault(import('@/layouts/main/Index' /* webpackChunkName: "" */))
const _1e545bc0 = () => interopDefault(import('@/pages/about/Index' /* webpackChunkName: "" */))
const _1a098c45 = () => interopDefault(import('@/pages/about/Contacts' /* webpackChunkName: "" */))
const _49d77fc5 = () => interopDefault(import('@/pages/about/News' /* webpackChunkName: "" */))
const _0cdeaf9c = () => interopDefault(import('@/pages/about/NewsAll' /* webpackChunkName: "" */))
const _a7c86b6e = () => interopDefault(import('@/pages/about/Vacancy' /* webpackChunkName: "" */))
const _2e89e7b2 = () => interopDefault(import('@/pages/auth/Login' /* webpackChunkName: "" */))
const _b7228760 = () => interopDefault(import('@/pages/auth/Registration' /* webpackChunkName: "" */))
const _6515fcae = () => interopDefault(import('@/pages/auth/RegistrationComplete' /* webpackChunkName: "" */))
const _2fd77452 = () => interopDefault(import('@/pages/auth/RestorePassword' /* webpackChunkName: "" */))
const _69322c70 = () => interopDefault(import('@/pages/auth/SetNewPassword' /* webpackChunkName: "" */))
const _ba99b33e = () => interopDefault(import('@/pages/auth/Logout' /* webpackChunkName: "" */))
const _beb76a8e = () => interopDefault(import('@/pages/basket/Index' /* webpackChunkName: "" */))
const _286f6f98 = () => interopDefault(import('@/pages/order/HowToOrder' /* webpackChunkName: "" */))
const _582f6202 = () => interopDefault(import('@/pages/order/Shipping' /* webpackChunkName: "" */))
const _9a6a7386 = () => interopDefault(import('@/pages/order/Distributors' /* webpackChunkName: "" */))
const _5f99cec1 = () => interopDefault(import('@/pages/order/Return' /* webpackChunkName: "" */))
const _7e7df20a = () => interopDefault(import('@/pages/order/PayMethods' /* webpackChunkName: "" */))
const _d99df12a = () => interopDefault(import('@/pages/order/SampleRequirements' /* webpackChunkName: "" */))
const _5ac02e02 = () => interopDefault(import('@/pages/order/SpecialOffers' /* webpackChunkName: "" */))
const _5d2f6234 = () => interopDefault(import('@/pages/payment/SberbankPayCompleted' /* webpackChunkName: "" */))
const _d9e215b6 = () => interopDefault(import('@/pages/policy/Index' /* webpackChunkName: "" */))
const _95dbe052 = () => interopDefault(import('@/pages/products/Index' /* webpackChunkName: "" */))
const _952f235a = () => interopDefault(import('@/pages/products/Order' /* webpackChunkName: "" */))
const _6ce871ec = () => interopDefault(import('@/pages/products/Pricelist' /* webpackChunkName: "" */))
const _43077080 = () => interopDefault(import('@/pages/products/antibodies/Index' /* webpackChunkName: "" */))
const _32df09b4 = () => interopDefault(import('@/pages/products/antibodies/Citing' /* webpackChunkName: "" */))
const _3e3f2b52 = () => interopDefault(import('@/pages/products/antibodies/AB-GFP' /* webpackChunkName: "" */))
const _3ece5084 = () => interopDefault(import('@/pages/products/antibodies/Anti-TagCGYFP' /* webpackChunkName: "" */))
const _3a89c9be = () => interopDefault(import('@/pages/products/antibodies/Anti-tRFP' /* webpackChunkName: "" */))
const _65e03350 = () => interopDefault(import('@/pages/products/antibodies/Anti-TurboGFPd' /* webpackChunkName: "" */))
const _1721cf1e = () => interopDefault(import('@/pages/products/antibodies/Anti-TurboYFP' /* webpackChunkName: "" */))
const _e9686fe6 = () => interopDefault(import('@/pages/products/antibodies/Anti-KillerRed' /* webpackChunkName: "" */))
const _7563acb2 = () => interopDefault(import('@/pages/products/cdna/Index' /* webpackChunkName: "" */))
const _4c559818 = () => interopDefault(import('@/pages/products/cdna/race/Index' /* webpackChunkName: "" */))
const _0a8df536 = () => interopDefault(import('@/pages/products/cdna/race/MintRaceCdna' /* webpackChunkName: "" */))
const _af1fb062 = () => interopDefault(import('@/pages/products/cdna/race/MintRacePrimers' /* webpackChunkName: "" */))
const _615f0dc0 = () => interopDefault(import('@/pages/products/cdna/normalization/Index' /* webpackChunkName: "" */))
const _8e94d320 = () => interopDefault(import('@/pages/products/cdna/normalization/dsn/Index' /* webpackChunkName: "" */))
const _53810276 = () => interopDefault(import('@/pages/products/cdna/normalization/dsn/Citing' /* webpackChunkName: "" */))
const _0ec0adcf = () => interopDefault(import('@/pages/products/cdna/normalization/trimmer2/Index' /* webpackChunkName: "" */))
const _824e5212 = () => interopDefault(import('@/pages/products/cdna/normalization/trimmer2/Citing' /* webpackChunkName: "" */))
const _1c6f5677 = () => interopDefault(import('@/pages/products/cdna/synthesis/Index' /* webpackChunkName: "" */))
const _61407e76 = () => interopDefault(import('@/pages/products/cdna/synthesis/MMLV' /* webpackChunkName: "" */))
const _660e0179 = () => interopDefault(import('@/pages/products/cdna/synthesis/MMLVCiting' /* webpackChunkName: "" */))
const _613fa3c6 = () => interopDefault(import('@/pages/products/cdna/synthesis/Mint' /* webpackChunkName: "" */))
const _c725e65e = () => interopDefault(import('@/pages/products/cdna/synthesis/MintCiting' /* webpackChunkName: "" */))
const _09415322 = () => interopDefault(import('@/pages/products/cdna/synthesis/OneTube' /* webpackChunkName: "" */))
const _782d2fa3 = () => interopDefault(import('@/pages/products/cdna/synthesis/OneTubeCiting' /* webpackChunkName: "" */))
const _77992854 = () => interopDefault(import('@/pages/products/cdna/synthesis/Magnus' /* webpackChunkName: "" */))
const _12f20834 = () => interopDefault(import('@/pages/products/components/Index' /* webpackChunkName: "" */))
const _7da6293c = () => interopDefault(import('@/pages/products/cloning/Index' /* webpackChunkName: "" */))
const _6c24b3c0 = () => interopDefault(import('@/pages/products/cloning/Vectors' /* webpackChunkName: "" */))
const _45ee706c = () => interopDefault(import('@/pages/products/cloning/QuickTa' /* webpackChunkName: "" */))
const _2a7f9584 = () => interopDefault(import('@/pages/products/cloning/competent-cells/Index' /* webpackChunkName: "" */))
const _1af6e5e2 = () => interopDefault(import('@/pages/products/cloning/competent-cells/Citing' /* webpackChunkName: "" */))
const _5f75a9ca = () => interopDefault(import('@/pages/products/practicum/Index' /* webpackChunkName: "" */))
const _bbcf11a8 = () => interopDefault(import('@/pages/products/practicum/Topics' /* webpackChunkName: "" */))
const _1779504c = () => interopDefault(import('@/pages/products/evaluation/Index' /* webpackChunkName: "" */))
const _43c49d1c = () => interopDefault(import('@/pages/products/evaluation/DnaLadders' /* webpackChunkName: "" */))
const _4a6d7034 = () => interopDefault(import('@/pages/products/evaluation/quantum/Index' /* webpackChunkName: "" */))
const _25c1a0fe = () => interopDefault(import('@/pages/products/evaluation/quantum/Concentration' /* webpackChunkName: "" */))
const _5af9d48e = () => interopDefault(import('@/pages/products/evaluation/quantum/Fragmentation' /* webpackChunkName: "" */))
const _333165e8 = () => interopDefault(import('@/pages/products/fp/Index' /* webpackChunkName: "" */))
const _ffbb3772 = () => interopDefault(import('@/pages/products/fp/basic/Index' /* webpackChunkName: "" */))
const _309fa82e = () => interopDefault(import('@/pages/products/fp/basic/tag-fps/Index' /* webpackChunkName: "" */))
const _0dc37152 = () => interopDefault(import('@/pages/products/fp/basic/tag-fps/Properties' /* webpackChunkName: "" */))
const _6f4c32d0 = () => interopDefault(import('@/pages/products/fp/basic/tag-fps/tag-bfp/Index' /* webpackChunkName: "" */))
const _3866b74e = () => interopDefault(import('@/pages/products/fp/basic/tag-fps/tag-bfp/Citing' /* webpackChunkName: "" */))
const _5c9e5599 = () => interopDefault(import('@/pages/products/fp/basic/tag-fps/tag-cfp/Index' /* webpackChunkName: "" */))
const _2cb0286d = () => interopDefault(import('@/pages/products/fp/basic/tag-fps/tag-cfp/Citing' /* webpackChunkName: "" */))
const _78a1f41b = () => interopDefault(import('@/pages/products/fp/basic/tag-fps/tag-gfp2/Index' /* webpackChunkName: "" */))
const _ddbf4baa = () => interopDefault(import('@/pages/products/fp/basic/tag-fps/tag-gfp2/Citing' /* webpackChunkName: "" */))
const _1a7fdfaf = () => interopDefault(import('@/pages/products/fp/basic/tag-fps/tag-yfp/Index' /* webpackChunkName: "" */))
const _2affe117 = () => interopDefault(import('@/pages/products/fp/basic/tag-fps/tag-yfp/Citing' /* webpackChunkName: "" */))
const _0ca0d6a8 = () => interopDefault(import('@/pages/products/fp/basic/tag-fps/tag-rfp/Index' /* webpackChunkName: "" */))
const _7cfdc93e = () => interopDefault(import('@/pages/products/fp/basic/tag-fps/tag-rfp/Citing' /* webpackChunkName: "" */))
const _3b89fae5 = () => interopDefault(import('@/pages/products/fp/basic/tag-fps/mkate2/Index' /* webpackChunkName: "" */))
const _2b392ca1 = () => interopDefault(import('@/pages/products/fp/basic/tag-fps/mkate2/Citing' /* webpackChunkName: "" */))
const _1b9e2a2f = () => interopDefault(import('@/pages/products/fp/basic/tag-fps/fusion-red/Index' /* webpackChunkName: "" */))
const _4daae697 = () => interopDefault(import('@/pages/products/fp/basic/tag-fps/fusion-red/Citing' /* webpackChunkName: "" */))
const _47cfed2d = () => interopDefault(import('@/pages/products/fp/basic/turbo-colors/Index' /* webpackChunkName: "" */))
const _c6c76c90 = () => interopDefault(import('@/pages/products/fp/basic/turbo-colors/Properties' /* webpackChunkName: "" */))
const _6904ee60 = () => interopDefault(import('@/pages/products/fp/basic/turbo-colors/turbo-gfp/Index' /* webpackChunkName: "" */))
const _a5c6aef4 = () => interopDefault(import('@/pages/products/fp/basic/turbo-colors/turbo-gfp/Citing' /* webpackChunkName: "" */))
const _5456871c = () => interopDefault(import('@/pages/products/fp/basic/turbo-colors/turbo-yfp/Index' /* webpackChunkName: "" */))
const _4b72c698 = () => interopDefault(import('@/pages/products/fp/basic/turbo-colors/turbo-yfp/Citing' /* webpackChunkName: "" */))
const _7014992a = () => interopDefault(import('@/pages/products/fp/basic/turbo-colors/turbo-rfp/Index' /* webpackChunkName: "" */))
const _2c4484db = () => interopDefault(import('@/pages/products/fp/basic/turbo-colors/turbo-rfp/Citing' /* webpackChunkName: "" */))
const _57995c46 = () => interopDefault(import('@/pages/products/fp/basic/turbo-colors/turbo-fp602/Index' /* webpackChunkName: "" */))
const _b08a96ae = () => interopDefault(import('@/pages/products/fp/basic/turbo-colors/turbo-fp602/Citing' /* webpackChunkName: "" */))
const _aaeceb86 = () => interopDefault(import('@/pages/products/fp/basic/turbo-colors/turbo-fp635/Index' /* webpackChunkName: "" */))
const _c7a8ef6e = () => interopDefault(import('@/pages/products/fp/basic/turbo-colors/turbo-fp635/Citing' /* webpackChunkName: "" */))
const _70dbc471 = () => interopDefault(import('@/pages/products/fp/basic/whole-body-imaging/Index' /* webpackChunkName: "" */))
const _8f2f4618 = () => interopDefault(import('@/pages/products/fp/basic/whole-body-imaging/Properties' /* webpackChunkName: "" */))
const _47102c72 = () => interopDefault(import('@/pages/products/fp/basic/whole-body-imaging/katushka2s/Index' /* webpackChunkName: "" */))
const _afedcc02 = () => interopDefault(import('@/pages/products/fp/basic/whole-body-imaging/katushka2s/Citing' /* webpackChunkName: "" */))
const _70c8660c = () => interopDefault(import('@/pages/products/fp/basic/whole-body-imaging/turbo-fp650/Index' /* webpackChunkName: "" */))
const _21619d2c = () => interopDefault(import('@/pages/products/fp/basic/whole-body-imaging/turbo-fp650/Citing' /* webpackChunkName: "" */))
const _9fde227a = () => interopDefault(import('@/pages/products/fp/basic/whole-body-imaging/nirfp/Index' /* webpackChunkName: "" */))
const _4790b483 = () => interopDefault(import('@/pages/products/fp/basic/whole-body-imaging/nirfp/Citing' /* webpackChunkName: "" */))
const _25469008 = () => interopDefault(import('@/pages/products/fp/basic/photoactivatable/Index' /* webpackChunkName: "" */))
const _c09f9086 = () => interopDefault(import('@/pages/products/fp/basic/photoactivatable/Properties' /* webpackChunkName: "" */))
const _34ab4a30 = () => interopDefault(import('@/pages/products/fp/basic/photoactivatable/ps-cfp2/Index' /* webpackChunkName: "" */))
const _75b66604 = () => interopDefault(import('@/pages/products/fp/basic/photoactivatable/ps-cfp2/Citing' /* webpackChunkName: "" */))
const _1af299b2 = () => interopDefault(import('@/pages/products/fp/basic/photoactivatable/kfp-red/Index' /* webpackChunkName: "" */))
const _38e46774 = () => interopDefault(import('@/pages/products/fp/basic/photoactivatable/kfp-red/Citing' /* webpackChunkName: "" */))
const _68f63f3a = () => interopDefault(import('@/pages/products/fp/basic/photoactivatable/pa-tag-rfp/Index' /* webpackChunkName: "" */))
const _1ceb5058 = () => interopDefault(import('@/pages/products/fp/basic/flim/Index' /* webpackChunkName: "" */))
const _40ca9d67 = () => interopDefault(import('@/pages/products/fp/basic/flim/bruslee/Index' /* webpackChunkName: "" */))
const _7993057a = () => interopDefault(import('@/pages/products/fp/basic/flim/slim/Index' /* webpackChunkName: "" */))
const _60d8613c = () => interopDefault(import('@/pages/products/fp/basic/Previous' /* webpackChunkName: "" */))
const _71f1081e = () => interopDefault(import('@/pages/products/fp/biosensors/Index' /* webpackChunkName: "" */))
const _60db3756 = () => interopDefault(import('@/pages/products/fp/biosensors/ProductsList' /* webpackChunkName: "" */))
const _6f1b49bb = () => interopDefault(import('@/pages/products/fp/biosensors/hyper/Index' /* webpackChunkName: "" */))
const _69d1b88b = () => interopDefault(import('@/pages/products/fp/biosensors/hyper/Citing' /* webpackChunkName: "" */))
const _4564b188 = () => interopDefault(import('@/pages/products/fp/biosensors/case12/Index' /* webpackChunkName: "" */))
const _5cb54a5e = () => interopDefault(import('@/pages/products/fp/biosensors/case12/Citing' /* webpackChunkName: "" */))
const _0a946324 = () => interopDefault(import('@/pages/products/fp/biosensors/casper3-gr/Index' /* webpackChunkName: "" */))
const _3d7bcc42 = () => interopDefault(import('@/pages/products/fp/biosensors/casper3-gr/Citing' /* webpackChunkName: "" */))
const _3d018894 = () => interopDefault(import('@/pages/products/fp/photosensibilizators/Index' /* webpackChunkName: "" */))
const _7c056620 = () => interopDefault(import('@/pages/products/fp/photosensibilizators/ProductsList' /* webpackChunkName: "" */))
const _de792460 = () => interopDefault(import('@/pages/products/fp/photosensibilizators/killer-red/Index' /* webpackChunkName: "" */))
const _7d2e1716 = () => interopDefault(import('@/pages/products/fp/photosensibilizators/killer-red/Citing' /* webpackChunkName: "" */))
const _44e57c4a = () => interopDefault(import('@/pages/products/fp/photosensibilizators/arrest-red/Index' /* webpackChunkName: "" */))
const _5317dbf5 = () => interopDefault(import('@/pages/products/fp/photosensibilizators/killer-orange/Index' /* webpackChunkName: "" */))
const _05676d91 = () => interopDefault(import('@/pages/products/fp/photosensibilizators/killer-orange/Citing' /* webpackChunkName: "" */))
const _3185c91c = () => interopDefault(import('@/pages/products/fp/recombinants/Index' /* webpackChunkName: "" */))
const _56e9f0a9 = () => interopDefault(import('@/pages/products/fp/vectors/Index' /* webpackChunkName: "" */))
const _21e092d6 = () => interopDefault(import('@/pages/products/fp/vectors/OrganelleLabeling' /* webpackChunkName: "" */))
const _0e6007d6 = () => interopDefault(import('@/pages/products/fp/vectors/PromoterTesting' /* webpackChunkName: "" */))
const _63b5d1d4 = () => interopDefault(import('@/pages/products/isolation/Index' /* webpackChunkName: "" */))
const _b0c179ca = () => interopDefault(import('@/pages/products/isolation/PlasmidKits' /* webpackChunkName: "" */))
const _08e39232 = () => interopDefault(import('@/pages/products/isolation/ExtractDnaFfpe' /* webpackChunkName: "" */))
const _2ec4a3e2 = () => interopDefault(import('@/pages/products/isolation/ExtractDnaBloodAndCells' /* webpackChunkName: "" */))
const _7cc33168 = () => interopDefault(import('@/pages/products/isolation/RNASolo' /* webpackChunkName: "" */))
const _f1dd8906 = () => interopDefault(import('@/pages/products/isolation/BisQuick' /* webpackChunkName: "" */))
const _cf0e7830 = () => interopDefault(import('@/pages/products/isolation/Cleanup' /* webpackChunkName: "" */))
const _4434fbb6 = () => interopDefault(import('@/pages/products/isolation/CleanupStPCR' /* webpackChunkName: "" */))
const _4434de25 = () => interopDefault(import('@/pages/products/isolation/CleanupStGel' /* webpackChunkName: "" */))
const _3a3876e9 = () => interopDefault(import('@/pages/products/isolation/cleanmag/Index' /* webpackChunkName: "" */))
const _24c9f0cc = () => interopDefault(import('@/pages/products/isolation/cleanmag/MagStand' /* webpackChunkName: "" */))
const _80d406b4 = () => interopDefault(import('@/pages/products/isolation/IntactRna' /* webpackChunkName: "" */))
const _2c9cdd9e = () => interopDefault(import('@/pages/products/isolation/RiboCare' /* webpackChunkName: "" */))
const _27ba2c10 = () => interopDefault(import('@/pages/products/isolation/DNAseE' /* webpackChunkName: "" */))
const _a8a034c0 = () => interopDefault(import('@/pages/products/isolation/ExtractRna' /* webpackChunkName: "" */))
const _769f35d8 = () => interopDefault(import('@/pages/products/isolation/CleanRna' /* webpackChunkName: "" */))
const _6a32a19b = () => interopDefault(import('@/pages/products/isolation/RCF-RPM' /* webpackChunkName: "" */))
const _2a3af5dc = () => interopDefault(import('@/pages/products/lenti/Index' /* webpackChunkName: "" */))
const _06ca3478 = () => interopDefault(import('@/pages/products/mycoplasma/Index' /* webpackChunkName: "" */))
const _63498af4 = () => interopDefault(import('@/pages/products/mycoplasma/MycoReport' /* webpackChunkName: "" */))
const _eab40b46 = () => interopDefault(import('@/pages/products/mycoplasma/MycoRealTime' /* webpackChunkName: "" */))
const _a7c38c52 = () => interopDefault(import('@/pages/products/nomotech/Index' /* webpackChunkName: "" */))
const _1e4c13bb = () => interopDefault(import('@/pages/products/nomotech/Insider' /* webpackChunkName: "" */))
const _50c3cada = () => interopDefault(import('@/pages/products/nomotech/InsiderFullRas' /* webpackChunkName: "" */))
const _2e762f29 = () => interopDefault(import('@/pages/products/nomotech/InsiderEGFR' /* webpackChunkName: "" */))
const _269202e9 = () => interopDefault(import('@/pages/products/nomotech/BRCA' /* webpackChunkName: "" */))
const _0fb054e9 = () => interopDefault(import('@/pages/products/nomotech/CYP2C9-VKORC1-CYP4F2' /* webpackChunkName: "" */))
const _89eb9c90 = () => interopDefault(import('@/pages/products/nomotech/CYP2C19' /* webpackChunkName: "" */))
const _1ad6bd40 = () => interopDefault(import('@/pages/products/nomotech/SLCO1B1' /* webpackChunkName: "" */))
const _2692485b = () => interopDefault(import('@/pages/products/nomotech/CES1' /* webpackChunkName: "" */))
const _a8504ad6 = () => interopDefault(import('@/pages/products/nomotech/F2-F5' /* webpackChunkName: "" */))
const _2697398c = () => interopDefault(import('@/pages/products/nomotech/NAT2' /* webpackChunkName: "" */))
const _2696dafc = () => interopDefault(import('@/pages/products/nomotech/MGMT' /* webpackChunkName: "" */))
const _021f793e = () => interopDefault(import('@/pages/products/nomotech/ExtractDNA2' /* webpackChunkName: "" */))
const _62a5039f = () => interopDefault(import('@/pages/products/nomotech/ExtractDnaFfpeNomotech' /* webpackChunkName: "" */))
const _61170827 = () => interopDefault(import('@/pages/products/pcr/Index' /* webpackChunkName: "" */))
const _e88969c0 = () => interopDefault(import('@/pages/products/pcr/bulk/Index' /* webpackChunkName: "" */))
const _4c1f3974 = () => interopDefault(import('@/pages/products/pcr/polymerases/Index' /* webpackChunkName: "" */))
const _599fd060 = () => interopDefault(import('@/pages/products/pcr/polymerases/Citing' /* webpackChunkName: "" */))
const _7062191e = () => interopDefault(import('@/pages/products/pcr/polymerases/encyclo/Index' /* webpackChunkName: "" */))
const _dd3654f0 = () => interopDefault(import('@/pages/products/pcr/polymerases/encyclo/Citing' /* webpackChunkName: "" */))
const _0a7f5142 = () => interopDefault(import('@/pages/products/pcr/polymerases/tersus/Index' /* webpackChunkName: "" */))
const _52ce5f67 = () => interopDefault(import('@/pages/products/pcr/polymerases/tersus/Citing' /* webpackChunkName: "" */))
const _d29586ec = () => interopDefault(import('@/pages/products/pcr/polymerases/snp-detect/Index' /* webpackChunkName: "" */))
const _35761f9c = () => interopDefault(import('@/pages/products/pcr/polymerases/snp-detect/Citing' /* webpackChunkName: "" */))
const _66c6cb7c = () => interopDefault(import('@/pages/products/pcr/polymerases/ktn-hs/Index' /* webpackChunkName: "" */))
const _69cd99ca = () => interopDefault(import('@/pages/products/pcr/polymerases/taq/Index' /* webpackChunkName: "" */))
const _c365f25c = () => interopDefault(import('@/pages/products/pcr/polymerases/hs-taq/Buffers' /* webpackChunkName: "" */))
const _0ff9519e = () => interopDefault(import('@/pages/products/pcr/polymerases/hs-taq/Index' /* webpackChunkName: "" */))
const _04294c56 = () => interopDefault(import('@/pages/products/pcr/polymerases/hs-taq/Citing' /* webpackChunkName: "" */))
const _1ddb2885 = () => interopDefault(import('@/pages/products/pcr/polymerases/sybr/Index' /* webpackChunkName: "" */))
const _79a72fdc = () => interopDefault(import('@/pages/products/pcr/polymerases/rox/Index' /* webpackChunkName: "" */))
const _40c37e22 = () => interopDefault(import('@/pages/products/pcr/mixes/Index' /* webpackChunkName: "" */))
const _659fddf8 = () => interopDefault(import('@/pages/products/pcr/mixes/Citing' /* webpackChunkName: "" */))
const _5071b3a0 = () => interopDefault(import('@/pages/products/pcr/mixes/ScreenMix' /* webpackChunkName: "" */))
const _0a59566a = () => interopDefault(import('@/pages/products/pcr/mixes/ScreenMixHs' /* webpackChunkName: "" */))
const _524c8226 = () => interopDefault(import('@/pages/products/pcr/mixes/ScreenMixHsUdg' /* webpackChunkName: "" */))
const _13c3e82e = () => interopDefault(import('@/pages/products/pcr/mixes/qPcrMixHs' /* webpackChunkName: "" */))
const _2bef3cc6 = () => interopDefault(import('@/pages/products/pcr/mixes/qPcrMixHsCiting' /* webpackChunkName: "" */))
const _1ae569e2 = () => interopDefault(import('@/pages/products/pcr/mixes/qPcrMixHsUdg' /* webpackChunkName: "" */))
const _5f1b7cbf = () => interopDefault(import('@/pages/products/pcr/mixes/qPcrMixHsSybr' /* webpackChunkName: "" */))
const _8089cec8 = () => interopDefault(import('@/pages/products/pcr/mixes/qPcrMixHsSybrRox' /* webpackChunkName: "" */))
const _1ae57d9c = () => interopDefault(import('@/pages/products/pcr/mixes/qPcrMixHsRox' /* webpackChunkName: "" */))
const _8ad79fdc = () => interopDefault(import('@/pages/products/pcr/mixes/KtnMixHs' /* webpackChunkName: "" */))
const _15e15188 = () => interopDefault(import('@/pages/profile/Index' /* webpackChunkName: "" */))
const _0743650c = () => interopDefault(import('@/pages/profile/Addresses' /* webpackChunkName: "" */))
const _889abcae = () => interopDefault(import('@/pages/profile/Contacts' /* webpackChunkName: "" */))
const _246492d2 = () => interopDefault(import('@/pages/profile/Discount' /* webpackChunkName: "" */))
const _915dfc8a = () => interopDefault(import('@/pages/profile/Orders' /* webpackChunkName: "" */))
const _438aab51 = () => interopDefault(import('@/pages/services/Index' /* webpackChunkName: "" */))
const _ebdd4934 = () => interopDefault(import('@/pages/services/chemistry/Index' /* webpackChunkName: "" */))
const _eb308c3c = () => interopDefault(import('@/pages/services/chemistry/Order' /* webpackChunkName: "" */))
const _62b26949 = () => interopDefault(import('@/pages/services/chemistry/organic-synthesis/Index' /* webpackChunkName: "" */))
const _7205d88e = () => interopDefault(import('@/pages/services/chemistry/semi-industrial-technologies/Index' /* webpackChunkName: "" */))
const _567bda56 = () => interopDefault(import('@/pages/services/synthesis/Index' /* webpackChunkName: "" */))
const _288955c8 = () => interopDefault(import('@/pages/services/synthesis/Kinination' /* webpackChunkName: "" */))
const _6e833b50 = () => interopDefault(import('@/pages/services/synthesis/Citing' /* webpackChunkName: "" */))
const _82b1fe94 = () => interopDefault(import('@/pages/services/synthesis/OrderForm' /* webpackChunkName: "" */))
const _56d238d2 = () => interopDefault(import('@/pages/services/synthesis/Order' /* webpackChunkName: "" */))
const _34066567 = () => interopDefault(import('@/pages/services/sequencing/sanger/Index' /* webpackChunkName: "" */))
const _345cc3e3 = () => interopDefault(import('@/pages/services/sequencing/sanger/Order' /* webpackChunkName: "" */))
const _424a125f = () => interopDefault(import('@/pages/services/sequencing/sanger/Citing' /* webpackChunkName: "" */))
const _48c9e34a = () => interopDefault(import('@/pages/services/sequencing/sanger/Requirements' /* webpackChunkName: "" */))
const _b1358450 = () => interopDefault(import('@/pages/services/sequencing/sanger/StandardPrimers' /* webpackChunkName: "" */))
const _50c20497 = () => interopDefault(import('@/pages/services/ges/Index' /* webpackChunkName: "" */))
const _51186313 = () => interopDefault(import('@/pages/services/ges/Order' /* webpackChunkName: "" */))
const _25167d8c = () => interopDefault(import('@/pages/services/ges/gene-synthesis/Index' /* webpackChunkName: "" */))
const _92b19e28 = () => interopDefault(import('@/pages/services/ges/gene-synthesis/Citing' /* webpackChunkName: "" */))
const _6dd7c1fb = () => interopDefault(import('@/pages/services/ges/mutagenesis/Index' /* webpackChunkName: "" */))
const _42a4484b = () => interopDefault(import('@/pages/services/ges/mutagenesis/Citing' /* webpackChunkName: "" */))
const _5807d534 = () => interopDefault(import('@/pages/services/ges/pcr-cloning/Index' /* webpackChunkName: "" */))
const _2e200ecc = () => interopDefault(import('@/pages/services/ges/amplicon-cloning/Index' /* webpackChunkName: "" */))
const _e35a7f34 = () => interopDefault(import('@/pages/services/Pricelist' /* webpackChunkName: "" */))
const _6901c567 = () => interopDefault(import('@/pages/sout/Ru' /* webpackChunkName: "" */))
const _9192622e = () => interopDefault(import('@/pages/sout/Lab' /* webpackChunkName: "" */))
const _4292d53e = () => interopDefault(import('@/pages/sout/Evrogen' /* webpackChunkName: "" */))
const _6901c44b = () => interopDefault(import('@/pages/sout/Ip' /* webpackChunkName: "" */))
const _0d2b051b = () => interopDefault(import('@/pages/search/Index' /* webpackChunkName: "" */))
const _0c60ce3c = () => interopDefault(import('@/pages/support/Index' /* webpackChunkName: "" */))
const _0f1901b7 = () => interopDefault(import('@/pages/support/Publications' /* webpackChunkName: "" */))
const _35a74e44 = () => interopDefault(import('@/pages/support/Citing' /* webpackChunkName: "" */))
const _177aa09a = () => interopDefault(import('@/pages/support/Technologies' /* webpackChunkName: "" */))
const _396a28a8 = () => interopDefault(import('@/pages/support/technologies/Normalization' /* webpackChunkName: "" */))
const _35af2f8d = () => interopDefault(import('@/pages/support/technologies/GenomeWalking' /* webpackChunkName: "" */))
const _6a38d4a9 = () => interopDefault(import('@/pages/support/technologies/MintcDNASynthesis' /* webpackChunkName: "" */))
const _5811f048 = () => interopDefault(import('@/pages/support/technologies/MOS' /* webpackChunkName: "" */))
const _6effa038 = () => interopDefault(import('@/pages/support/technologies/LongProductAmplification' /* webpackChunkName: "" */))
const _9a921858 = () => interopDefault(import('@/pages/support/technologies/Smart' /* webpackChunkName: "" */))
const _0359149e = () => interopDefault(import('@/pages/support/technologies/StepOutRace' /* webpackChunkName: "" */))
const _5811ba5a = () => interopDefault(import('@/pages/support/technologies/Ssh' /* webpackChunkName: "" */))
const _a944fe62 = () => interopDefault(import('@/pages/support/technologies/WtbPcr' /* webpackChunkName: "" */))
const _7a00aa2f = () => interopDefault(import('@/pages/support/technologies/RnaElectrophoresis' /* webpackChunkName: "" */))
const _66b98384 = () => interopDefault(import('@/pages/support/technologies/RnaIsolation' /* webpackChunkName: "" */))
const _4b5f5236 = () => interopDefault(import('@/pages/support/technologies/PlasmidPurification' /* webpackChunkName: "" */))
const _72946552 = () => interopDefault(import('@/pages/support/technologies/BloodPreparation' /* webpackChunkName: "" */))
const _d9ecbfd6 = () => interopDefault(import('@/pages/support/LicenseStatements' /* webpackChunkName: "" */))
const _1278f238 = () => interopDefault(import('@/pages/support/license-statements/EvrogenFpLicense' /* webpackChunkName: "" */))
const _e4508756 = () => interopDefault(import('@/pages/support/license-statements/EvrogenDsnLicense' /* webpackChunkName: "" */))
const _62e04d3a = () => interopDefault(import('@/pages/support/license-statements/InvitrogenGatewayLicense' /* webpackChunkName: "" */))
const _22bfef41 = () => interopDefault(import('@/pages/support/Literature' /* webpackChunkName: "" */))
const _ff348718 = () => interopDefault(import('@/pages/sequence/Index' /* webpackChunkName: "" */))
const _037959a8 = () => interopDefault(import('@/pages/sequence/SequenceEditor' /* webpackChunkName: "" */))
const _8d461788 = () => interopDefault(import('@/pages/payment/payment-redirect' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/BlankPage",
    component: _8d462bf6,
    pathToRegexpOptions: {"strict":true},
    name: "BlankPage"
  }, {
    path: "/Index",
    component: _49450b58,
    pathToRegexpOptions: {"strict":true},
    name: "Index"
  }, {
    path: "/about/Contacts",
    component: _7e923403,
    pathToRegexpOptions: {"strict":true},
    name: "about-Contacts"
  }, {
    path: "/about/Index",
    component: _040d325c,
    pathToRegexpOptions: {"strict":true},
    name: "about-Index"
  }, {
    path: "/about/News",
    component: _75982a83,
    pathToRegexpOptions: {"strict":true},
    name: "about-News"
  }, {
    path: "/about/NewsAll",
    component: _722b07ce,
    pathToRegexpOptions: {"strict":true},
    name: "about-NewsAll"
  }, {
    path: "/about/Vacancy",
    component: _ba5df80a,
    pathToRegexpOptions: {"strict":true},
    name: "about-Vacancy"
  }, {
    path: "/auth/Login",
    component: _69cad3f0,
    pathToRegexpOptions: {"strict":true},
    name: "auth-Login"
  }, {
    path: "/auth/Logout",
    component: _1ca0b293,
    pathToRegexpOptions: {"strict":true},
    name: "auth-Logout"
  }, {
    path: "/auth/Registration",
    component: _b8d384fc,
    pathToRegexpOptions: {"strict":true},
    name: "auth-Registration"
  }, {
    path: "/auth/RegistrationComplete",
    component: _102cf45b,
    pathToRegexpOptions: {"strict":true},
    name: "auth-RegistrationComplete"
  }, {
    path: "/auth/RestorePassword",
    component: _53ba7910,
    pathToRegexpOptions: {"strict":true},
    name: "auth-RestorePassword"
  }, {
    path: "/auth/SetNewPassword",
    component: _023d41bc,
    pathToRegexpOptions: {"strict":true},
    name: "auth-SetNewPassword"
  }, {
    path: "/basket/Index",
    component: _a4df6c12,
    pathToRegexpOptions: {"strict":true},
    name: "basket-Index"
  }, {
    path: "/order/Distributors",
    component: _a81d220a,
    pathToRegexpOptions: {"strict":true},
    name: "order-Distributors"
  }, {
    path: "/order/HowToOrder",
    component: _958be21c,
    pathToRegexpOptions: {"strict":true},
    name: "order-HowToOrder"
  }, {
    path: "/order/PayMethods",
    component: _62cfdb8e,
    pathToRegexpOptions: {"strict":true},
    name: "order-PayMethods"
  }, {
    path: "/order/Return",
    component: _2e9489ff,
    pathToRegexpOptions: {"strict":true},
    name: "order-Return"
  }, {
    path: "/order/SampleRequirements",
    component: _6da656ae,
    pathToRegexpOptions: {"strict":true},
    name: "order-SampleRequirements"
  }, {
    path: "/order/Shipping",
    component: _5d05ec86,
    pathToRegexpOptions: {"strict":true},
    name: "order-Shipping"
  }, {
    path: "/order/SpecialOffers",
    component: _4af03c31,
    pathToRegexpOptions: {"strict":true},
    name: "order-SpecialOffers"
  }, {
    path: "/payment/payment-redirect",
    component: _29f81e0c,
    pathToRegexpOptions: {"strict":true},
    name: "payment-payment-redirect"
  }, {
    path: "/payment/SberbankPayCompleted",
    component: _19f21ff2,
    pathToRegexpOptions: {"strict":true},
    name: "payment-SberbankPayCompleted"
  }, {
    path: "/policy/Index",
    component: _86f0433a,
    pathToRegexpOptions: {"strict":true},
    name: "policy-Index"
  }, {
    path: "/products/Index",
    component: _d8aac2d6,
    pathToRegexpOptions: {"strict":true},
    name: "products-Index"
  }, {
    path: "/products/Order",
    component: _a71541de,
    pathToRegexpOptions: {"strict":true},
    name: "products-Order"
  }, {
    path: "/products/Pricelist",
    component: _fa38a9ac,
    pathToRegexpOptions: {"strict":true},
    name: "products-Pricelist"
  }, {
    path: "/profile/Addresses",
    component: _739faf84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-Addresses"
  }, {
    path: "/profile/Contacts",
    component: _6cc0a5e7,
    pathToRegexpOptions: {"strict":true},
    name: "profile-Contacts"
  }, {
    path: "/profile/Discount",
    component: _404b93d5,
    pathToRegexpOptions: {"strict":true},
    name: "profile-Discount"
  }, {
    path: "/profile/Index",
    component: _07024124,
    pathToRegexpOptions: {"strict":true},
    name: "profile-Index"
  }, {
    path: "/profile/Orders",
    component: _d0d4030e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-Orders"
  }, {
    path: "/search/Index",
    component: _ace1f94e,
    pathToRegexpOptions: {"strict":true},
    name: "search-Index"
  }, {
    path: "/sequence/Header",
    component: _0ba03d5d,
    pathToRegexpOptions: {"strict":true},
    name: "sequence-Header"
  }, {
    path: "/sequence/Index",
    component: _0426969c,
    pathToRegexpOptions: {"strict":true},
    name: "sequence-Index"
  }, {
    path: "/sequence/SequenceEditor",
    component: _09db2ade,
    pathToRegexpOptions: {"strict":true},
    name: "sequence-SequenceEditor"
  }, {
    path: "/services/Index",
    component: _95d625e2,
    pathToRegexpOptions: {"strict":true},
    name: "services-Index"
  }, {
    path: "/services/Pricelist",
    component: _312e2ca4,
    pathToRegexpOptions: {"strict":true},
    name: "services-Pricelist"
  }, {
    path: "/sout/Evrogen",
    component: _487c09fc,
    pathToRegexpOptions: {"strict":true},
    name: "sout-Evrogen"
  }, {
    path: "/sout/Ip",
    component: _3b292f06,
    pathToRegexpOptions: {"strict":true},
    name: "sout-Ip"
  }, {
    path: "/sout/Lab",
    component: _1a508db2,
    pathToRegexpOptions: {"strict":true},
    name: "sout-Lab"
  }, {
    path: "/sout/Ru",
    component: _1be508ce,
    pathToRegexpOptions: {"strict":true},
    name: "sout-Ru"
  }, {
    path: "/support/Citing",
    component: _3170de82,
    pathToRegexpOptions: {"strict":true},
    name: "support-Citing"
  }, {
    path: "/support/Index",
    component: _3e59de14,
    pathToRegexpOptions: {"strict":true},
    name: "support-Index"
  }, {
    path: "/support/LicenseStatements",
    component: _b7970672,
    pathToRegexpOptions: {"strict":true},
    name: "support-LicenseStatements"
  }, {
    path: "/support/Literature",
    component: _5b6275ff,
    pathToRegexpOptions: {"strict":true},
    name: "support-Literature"
  }, {
    path: "/support/Publications",
    component: _72fccbf5,
    pathToRegexpOptions: {"strict":true},
    name: "support-Publications"
  }, {
    path: "/support/Technologies",
    component: _21de4158,
    pathToRegexpOptions: {"strict":true},
    name: "support-Technologies"
  }, {
    path: "/products/antibodies/AB-GFP",
    component: _15477015,
    pathToRegexpOptions: {"strict":true},
    name: "products-antibodies-AB-GFP"
  }, {
    path: "/products/antibodies/Anti-KillerRed",
    component: _3d2a066a,
    pathToRegexpOptions: {"strict":true},
    name: "products-antibodies-Anti-KillerRed"
  }, {
    path: "/products/antibodies/Anti-TagCGYFP",
    component: _61cc7df0,
    pathToRegexpOptions: {"strict":true},
    name: "products-antibodies-Anti-TagCGYFP"
  }, {
    path: "/products/antibodies/Anti-tRFP",
    component: _dd54965a,
    pathToRegexpOptions: {"strict":true},
    name: "products-antibodies-Anti-tRFP"
  }, {
    path: "/products/antibodies/Anti-TurboGFPd",
    component: _4c10fd96,
    pathToRegexpOptions: {"strict":true},
    name: "products-antibodies-Anti-TurboGFPd"
  }, {
    path: "/products/antibodies/Anti-TurboYFP",
    component: _9b6a7560,
    pathToRegexpOptions: {"strict":true},
    name: "products-antibodies-Anti-TurboYFP"
  }, {
    path: "/products/antibodies/Citing",
    component: _a056fd38,
    pathToRegexpOptions: {"strict":true},
    name: "products-antibodies-Citing"
  }, {
    path: "/products/antibodies/Index",
    component: _216556f2,
    pathToRegexpOptions: {"strict":true},
    name: "products-antibodies-Index"
  }, {
    path: "/products/cdna/Index",
    component: _cc162338,
    pathToRegexpOptions: {"strict":true},
    name: "products-cdna-Index"
  }, {
    path: "/products/cloning/Index",
    component: _42d7b6a0,
    pathToRegexpOptions: {"strict":true},
    name: "products-cloning-Index"
  }, {
    path: "/products/cloning/QuickTa",
    component: _334164f0,
    pathToRegexpOptions: {"strict":true},
    name: "products-cloning-QuickTa"
  }, {
    path: "/products/cloning/Vectors",
    component: _033dde44,
    pathToRegexpOptions: {"strict":true},
    name: "products-cloning-Vectors"
  }, {
    path: "/products/components/Index",
    component: _a9448534,
    pathToRegexpOptions: {"strict":true},
    name: "products-components-Index"
  }, {
    path: "/products/evaluation/DnaLadders",
    component: _04e422a0,
    pathToRegexpOptions: {"strict":true},
    name: "products-evaluation-DnaLadders"
  }, {
    path: "/products/evaluation/Index",
    component: _42da6be8,
    pathToRegexpOptions: {"strict":true},
    name: "products-evaluation-Index"
  }, {
    path: "/products/fp/Index",
    component: _573a5b1a,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-Index"
  }, {
    path: "/products/isolation/BisQuick",
    component: _4c9365af,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-BisQuick"
  }, {
    path: "/products/isolation/Citing",
    component: _3b06bdc2,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-Citing"
  }, {
    path: "/products/isolation/CleanRna",
    component: _7596ba8a,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-CleanRna"
  }, {
    path: "/products/isolation/Cleanup",
    component: _2f069926,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-Cleanup"
  }, {
    path: "/products/isolation/CleanupStGel",
    component: _84565152,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-CleanupStGel"
  }, {
    path: "/products/isolation/CleanupStPCR",
    component: _5e79c468,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-CleanupStPCR"
  }, {
    path: "/products/isolation/DNAseE",
    component: _4bffc5ac,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-DNAseE"
  }, {
    path: "/products/isolation/ExtractDnaBloodAndCells",
    component: _64c7e020,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-ExtractDnaBloodAndCells"
  }, {
    path: "/products/isolation/ExtractDnaFfpe",
    component: _373c2099,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-ExtractDnaFfpe"
  }, {
    path: "/products/isolation/ExtractRna",
    component: _3459b05c,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-ExtractRna"
  }, {
    path: "/products/isolation/Index",
    component: _46eb16d4,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-Index"
  }, {
    path: "/products/isolation/IntactRna",
    component: _59c23164,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-IntactRna"
  }, {
    path: "/products/isolation/PlasmidKits",
    component: _232c83d9,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-PlasmidKits"
  }, {
    path: "/products/isolation/RCF-RPM",
    component: _9f37354e,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-RCF-RPM"
  }, {
    path: "/products/isolation/RiboCare",
    component: _16e9c163,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-RiboCare"
  }, {
    path: "/products/isolation/RNASolo",
    component: _354372b4,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-RNASolo"
  }, {
    path: "/products/lenti/Index",
    component: _7d4f29d0,
    pathToRegexpOptions: {"strict":true},
    name: "products-lenti-Index"
  }, {
    path: "/products/mycoplasma/Index",
    component: _62fe27aa,
    pathToRegexpOptions: {"strict":true},
    name: "products-mycoplasma-Index"
  }, {
    path: "/products/mycoplasma/MycoRealTime",
    component: _230b789b,
    pathToRegexpOptions: {"strict":true},
    name: "products-mycoplasma-MycoRealTime"
  }, {
    path: "/products/mycoplasma/MycoReport",
    component: _12f8cdc4,
    pathToRegexpOptions: {"strict":true},
    name: "products-mycoplasma-MycoReport"
  }, {
    path: "/products/nomotech/BRCA",
    component: _8d709ab2,
    pathToRegexpOptions: {"strict":true},
    name: "products-nomotech-BRCA"
  }, {
    path: "/products/nomotech/CES1",
    component: _e83821ce,
    pathToRegexpOptions: {"strict":true},
    name: "products-nomotech-CES1"
  }, {
    path: "/products/nomotech/CYP2C19",
    component: _5f04f62c,
    pathToRegexpOptions: {"strict":true},
    name: "products-nomotech-CYP2C19"
  }, {
    path: "/products/nomotech/CYP2C9-VKORC1-CYP4F2",
    component: _fa7e0eb2,
    pathToRegexpOptions: {"strict":true},
    name: "products-nomotech-CYP2C9-VKORC1-CYP4F2"
  }, {
    path: "/products/nomotech/ExtractDNA2",
    component: _7b8685da,
    pathToRegexpOptions: {"strict":true},
    name: "products-nomotech-ExtractDNA2"
  }, {
    path: "/products/nomotech/ExtractDnaFfpeNomotech",
    component: _0072ea46,
    pathToRegexpOptions: {"strict":true},
    name: "products-nomotech-ExtractDnaFfpeNomotech"
  }, {
    path: "/products/nomotech/F2-F5",
    component: _419bd5c7,
    pathToRegexpOptions: {"strict":true},
    name: "products-nomotech-F2-F5"
  }, {
    path: "/products/nomotech/Index",
    component: _258677ee,
    pathToRegexpOptions: {"strict":true},
    name: "products-nomotech-Index"
  }, {
    path: "/products/nomotech/Insider",
    component: _1c28ad6d,
    pathToRegexpOptions: {"strict":true},
    name: "products-nomotech-Insider"
  }, {
    path: "/products/nomotech/InsiderEGFR",
    component: _bc50f64a,
    pathToRegexpOptions: {"strict":true},
    name: "products-nomotech-InsiderEGFR"
  }, {
    path: "/products/nomotech/InsiderFullRas",
    component: _0ca2c298,
    pathToRegexpOptions: {"strict":true},
    name: "products-nomotech-InsiderFullRas"
  }, {
    path: "/products/nomotech/MGMT",
    component: _07a76d8c,
    pathToRegexpOptions: {"strict":true},
    name: "products-nomotech-MGMT"
  }, {
    path: "/products/nomotech/NAT2",
    component: _30badfca,
    pathToRegexpOptions: {"strict":true},
    name: "products-nomotech-NAT2"
  }, {
    path: "/products/nomotech/SLCO1B1",
    component: _5877e092,
    pathToRegexpOptions: {"strict":true},
    name: "products-nomotech-SLCO1B1"
  }, {
    path: "/products/pcr/Index",
    component: _15d2a836,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-Index"
  }, {
    path: "/products/practicum/Index",
    component: _78e9a0f0,
    pathToRegexpOptions: {"strict":true},
    name: "products-practicum-Index"
  }, {
    path: "/products/practicum/Topics",
    component: _5343f05e,
    pathToRegexpOptions: {"strict":true},
    name: "products-practicum-Topics"
  }, {
    path: "/services/chemistry/Index",
    component: _210699b8,
    pathToRegexpOptions: {"strict":true},
    name: "services-chemistry-Index"
  }, {
    path: "/services/chemistry/Order",
    component: _084773a0,
    pathToRegexpOptions: {"strict":true},
    name: "services-chemistry-Order"
  }, {
    path: "/services/ges/Index",
    component: _35e41f56,
    pathToRegexpOptions: {"strict":true},
    name: "services-ges-Index"
  }, {
    path: "/services/ges/Order",
    component: _044e9e5e,
    pathToRegexpOptions: {"strict":true},
    name: "services-ges-Order"
  }, {
    path: "/services/synthesis/Citing",
    component: _b2affafc,
    pathToRegexpOptions: {"strict":true},
    name: "services-synthesis-Citing"
  }, {
    path: "/services/synthesis/Index",
    component: _73ae3a14,
    pathToRegexpOptions: {"strict":true},
    name: "services-synthesis-Index"
  }, {
    path: "/services/synthesis/Kinination",
    component: _4b62794e,
    pathToRegexpOptions: {"strict":true},
    name: "services-synthesis-Kinination"
  }, {
    path: "/services/synthesis/Order",
    component: _e70e0ae0,
    pathToRegexpOptions: {"strict":true},
    name: "services-synthesis-Order"
  }, {
    path: "/services/synthesis/OrderForm",
    component: _bcdaa518,
    pathToRegexpOptions: {"strict":true},
    name: "services-synthesis-OrderForm"
  }, {
    path: "/support/license-statements/EvrogenDsnLicense",
    component: _628914da,
    pathToRegexpOptions: {"strict":true},
    name: "support-license-statements-EvrogenDsnLicense"
  }, {
    path: "/support/license-statements/EvrogenFpLicense",
    component: _68583196,
    pathToRegexpOptions: {"strict":true},
    name: "support-license-statements-EvrogenFpLicense"
  }, {
    path: "/support/license-statements/InvitrogenGatewayLicense",
    component: _5bf27295,
    pathToRegexpOptions: {"strict":true},
    name: "support-license-statements-InvitrogenGatewayLicense"
  }, {
    path: "/support/technologies/BloodPreparation",
    component: _00884484,
    pathToRegexpOptions: {"strict":true},
    name: "support-technologies-BloodPreparation"
  }, {
    path: "/support/technologies/GenomeWalking",
    component: _54cea44b,
    pathToRegexpOptions: {"strict":true},
    name: "support-technologies-GenomeWalking"
  }, {
    path: "/support/technologies/LongProductAmplification",
    component: _62b53b2c,
    pathToRegexpOptions: {"strict":true},
    name: "support-technologies-LongProductAmplification"
  }, {
    path: "/support/technologies/MintcDNASynthesis",
    component: _61839867,
    pathToRegexpOptions: {"strict":true},
    name: "support-technologies-MintcDNASynthesis"
  }, {
    path: "/support/technologies/MOS",
    component: _3d2b349a,
    pathToRegexpOptions: {"strict":true},
    name: "support-technologies-MOS"
  }, {
    path: "/support/technologies/Normalization",
    component: _bc3cd034,
    pathToRegexpOptions: {"strict":true},
    name: "support-technologies-Normalization"
  }, {
    path: "/support/technologies/PlasmidPurification",
    component: _4ef9b5f4,
    pathToRegexpOptions: {"strict":true},
    name: "support-technologies-PlasmidPurification"
  }, {
    path: "/support/technologies/RnaElectrophoresis",
    component: _f02c573e,
    pathToRegexpOptions: {"strict":true},
    name: "support-technologies-RnaElectrophoresis"
  }, {
    path: "/support/technologies/RnaIsolation",
    component: _4580b720,
    pathToRegexpOptions: {"strict":true},
    name: "support-technologies-RnaIsolation"
  }, {
    path: "/support/technologies/Smart",
    component: _c4da19dc,
    pathToRegexpOptions: {"strict":true},
    name: "support-technologies-Smart"
  }, {
    path: "/support/technologies/Ssh",
    component: _8db207de,
    pathToRegexpOptions: {"strict":true},
    name: "support-technologies-Ssh"
  }, {
    path: "/support/technologies/StepOutRace",
    component: _7c358a5c,
    pathToRegexpOptions: {"strict":true},
    name: "support-technologies-StepOutRace"
  }, {
    path: "/support/technologies/WtbPcr",
    component: _3bb8b201,
    pathToRegexpOptions: {"strict":true},
    name: "support-technologies-WtbPcr"
  }, {
    path: "/products/cdna/normalization/Index",
    component: _08a3e2f2,
    pathToRegexpOptions: {"strict":true},
    name: "products-cdna-normalization-Index"
  }, {
    path: "/products/cdna/race/Index",
    component: _dd34d69c,
    pathToRegexpOptions: {"strict":true},
    name: "products-cdna-race-Index"
  }, {
    path: "/products/cdna/race/MintRaceCdna",
    component: _891942d2,
    pathToRegexpOptions: {"strict":true},
    name: "products-cdna-race-MintRaceCdna"
  }, {
    path: "/products/cdna/race/MintRacePrimers",
    component: _11f5ab8d,
    pathToRegexpOptions: {"strict":true},
    name: "products-cdna-race-MintRacePrimers"
  }, {
    path: "/products/cdna/synthesis/Index",
    component: _15305529,
    pathToRegexpOptions: {"strict":true},
    name: "products-cdna-synthesis-Index"
  }, {
    path: "/products/cdna/synthesis/Magnus",
    component: _2661b6dc,
    pathToRegexpOptions: {"strict":true},
    name: "products-cdna-synthesis-Magnus"
  }, {
    path: "/products/cdna/synthesis/Mint",
    component: _2d98cf5b,
    pathToRegexpOptions: {"strict":true},
    name: "products-cdna-synthesis-Mint"
  }, {
    path: "/products/cdna/synthesis/MintCiting",
    component: _43143f8f,
    pathToRegexpOptions: {"strict":true},
    name: "products-cdna-synthesis-MintCiting"
  }, {
    path: "/products/cdna/synthesis/MMLV",
    component: _28bece03,
    pathToRegexpOptions: {"strict":true},
    name: "products-cdna-synthesis-MMLV"
  }, {
    path: "/products/cdna/synthesis/MMLVCiting",
    component: _974abf92,
    pathToRegexpOptions: {"strict":true},
    name: "products-cdna-synthesis-MMLVCiting"
  }, {
    path: "/products/cdna/synthesis/OneTube",
    component: _6f161aa1,
    pathToRegexpOptions: {"strict":true},
    name: "products-cdna-synthesis-OneTube"
  }, {
    path: "/products/cdna/synthesis/OneTubeCiting",
    component: _c5bd0356,
    pathToRegexpOptions: {"strict":true},
    name: "products-cdna-synthesis-OneTubeCiting"
  }, {
    path: "/products/cloning/competent-cells/Citing",
    component: _0724fe94,
    pathToRegexpOptions: {"strict":true},
    name: "products-cloning-competent-cells-Citing"
  }, {
    path: "/products/cloning/competent-cells/Index",
    component: _2c7873c2,
    pathToRegexpOptions: {"strict":true},
    name: "products-cloning-competent-cells-Index"
  }, {
    path: "/products/evaluation/quantum/Concentration",
    component: _2f140233,
    pathToRegexpOptions: {"strict":true},
    name: "products-evaluation-quantum-Concentration"
  }, {
    path: "/products/evaluation/quantum/Fragmentation",
    component: _572a0c6b,
    pathToRegexpOptions: {"strict":true},
    name: "products-evaluation-quantum-Fragmentation"
  }, {
    path: "/products/evaluation/quantum/Index",
    component: _1f0ecbd0,
    pathToRegexpOptions: {"strict":true},
    name: "products-evaluation-quantum-Index"
  }, {
    path: "/products/fp/basic/Index",
    component: _75463679,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-Index"
  }, {
    path: "/products/fp/basic/Previous",
    component: _025b57a0,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-Previous"
  }, {
    path: "/products/fp/biosensors/Index",
    component: _083ac0dc,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-biosensors-Index"
  }, {
    path: "/products/fp/biosensors/ProductsList",
    component: _b521d9f0,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-biosensors-ProductsList"
  }, {
    path: "/products/fp/photosensibilizators/Index",
    component: _77aa5ed2,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-photosensibilizators-Index"
  }, {
    path: "/products/fp/photosensibilizators/ProductsList",
    component: _93f4175c,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-photosensibilizators-ProductsList"
  }, {
    path: "/products/fp/recombinants/Index",
    component: _34e858b0,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-recombinants-Index"
  }, {
    path: "/products/fp/vectors/Index",
    component: _30db394a,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-vectors-Index"
  }, {
    path: "/products/fp/vectors/OrganelleLabeling",
    component: _a98bdff0,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-vectors-OrganelleLabeling"
  }, {
    path: "/products/fp/vectors/PromoterTesting",
    component: _10353f72,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-vectors-PromoterTesting"
  }, {
    path: "/products/isolation/cleanmag/Index",
    component: _d23ae0ca,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-cleanmag-Index"
  }, {
    path: "/products/isolation/cleanmag/MagStand",
    component: _fbf1c6ec,
    pathToRegexpOptions: {"strict":true},
    name: "products-isolation-cleanmag-MagStand"
  }, {
    path: "/products/pcr/bulk/Index",
    component: _ac23ee5c,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-bulk-Index"
  }, {
    path: "/products/pcr/mixes/Citing",
    component: _da6a4394,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-mixes-Citing"
  }, {
    path: "/products/pcr/mixes/Index",
    component: _32b1d040,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-mixes-Index"
  }, {
    path: "/products/pcr/mixes/KtnMixHs",
    component: _65b007c4,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-mixes-KtnMixHs"
  }, {
    path: "/products/pcr/mixes/qPcrMixHs",
    component: _394a31b2,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-mixes-qPcrMixHs"
  }, {
    path: "/products/pcr/mixes/qPcrMixHsCiting",
    component: _13bf634a,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-mixes-qPcrMixHsCiting"
  }, {
    path: "/products/pcr/mixes/qPcrMixHsRox",
    component: _180a2838,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-mixes-qPcrMixHsRox"
  }, {
    path: "/products/pcr/mixes/qPcrMixHsSybr",
    component: _3b560c06,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-mixes-qPcrMixHsSybr"
  }, {
    path: "/products/pcr/mixes/qPcrMixHsSybrRox",
    component: _395e3d64,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-mixes-qPcrMixHsSybrRox"
  }, {
    path: "/products/pcr/mixes/qPcrMixHsUdg",
    component: _7ef8bf41,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-mixes-qPcrMixHsUdg"
  }, {
    path: "/products/pcr/mixes/ScreenMix",
    component: _1795db5e,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-mixes-ScreenMix"
  }, {
    path: "/products/pcr/mixes/ScreenMixHs",
    component: _94bf84ee,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-mixes-ScreenMixHs"
  }, {
    path: "/products/pcr/mixes/ScreenMixHsUdg",
    component: _1c4f94c2,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-mixes-ScreenMixHsUdg"
  }, {
    path: "/products/pcr/polymerases/Citing",
    component: _63b63412,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-polymerases-Citing"
  }, {
    path: "/products/pcr/polymerases/Index",
    component: _eb68d2f8,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-polymerases-Index"
  }, {
    path: "/services/chemistry/organic-synthesis/Index",
    component: _9012cbf2,
    pathToRegexpOptions: {"strict":true},
    name: "services-chemistry-organic-synthesis-Index"
  }, {
    path: "/services/chemistry/semi-industrial-technologies/Index",
    component: _bac97480,
    pathToRegexpOptions: {"strict":true},
    name: "services-chemistry-semi-industrial-technologies-Index"
  }, {
    path: "/services/ges/amplicon-cloning/Index",
    component: _0cef3f7e,
    pathToRegexpOptions: {"strict":true},
    name: "services-ges-amplicon-cloning-Index"
  }, {
    path: "/services/ges/gene-synthesis/Citing",
    component: _a5ce43ac,
    pathToRegexpOptions: {"strict":true},
    name: "services-ges-gene-synthesis-Citing"
  }, {
    path: "/services/ges/gene-synthesis/Index",
    component: _635f496c,
    pathToRegexpOptions: {"strict":true},
    name: "services-ges-gene-synthesis-Index"
  }, {
    path: "/services/ges/mutagenesis/Citing",
    component: _76d28306,
    pathToRegexpOptions: {"strict":true},
    name: "services-ges-mutagenesis-Citing"
  }, {
    path: "/services/ges/mutagenesis/Index",
    component: _094a92b9,
    pathToRegexpOptions: {"strict":true},
    name: "services-ges-mutagenesis-Index"
  }, {
    path: "/services/ges/pcr-cloning/Index",
    component: _6a5c6172,
    pathToRegexpOptions: {"strict":true},
    name: "services-ges-pcr-cloning-Index"
  }, {
    path: "/services/sequencing/sanger/Citing",
    component: _85a4ffde,
    pathToRegexpOptions: {"strict":true},
    name: "services-sequencing-sanger-Citing"
  }, {
    path: "/services/sequencing/sanger/Index",
    component: _00cb4ea5,
    pathToRegexpOptions: {"strict":true},
    name: "services-sequencing-sanger-Index"
  }, {
    path: "/services/sequencing/sanger/Order",
    component: _19960f21,
    pathToRegexpOptions: {"strict":true},
    name: "services-sequencing-sanger-Order"
  }, {
    path: "/services/sequencing/sanger/Requirements",
    component: _101e428d,
    pathToRegexpOptions: {"strict":true},
    name: "services-sequencing-sanger-Requirements"
  }, {
    path: "/services/sequencing/sanger/StandardPrimers",
    component: _06fe8f16,
    pathToRegexpOptions: {"strict":true},
    name: "services-sequencing-sanger-StandardPrimers"
  }, {
    path: "/products/cdna/normalization/dsn/Citing",
    component: _2793d098,
    pathToRegexpOptions: {"strict":true},
    name: "products-cdna-normalization-dsn-Citing"
  }, {
    path: "/products/cdna/normalization/dsn/Index",
    component: _024fb6a2,
    pathToRegexpOptions: {"strict":true},
    name: "products-cdna-normalization-dsn-Index"
  }, {
    path: "/products/cdna/normalization/trimmer2/Citing",
    component: _f35d7fae,
    pathToRegexpOptions: {"strict":true},
    name: "products-cdna-normalization-trimmer2-Citing"
  }, {
    path: "/products/cdna/normalization/trimmer2/Index",
    component: _72a32b8d,
    pathToRegexpOptions: {"strict":true},
    name: "products-cdna-normalization-trimmer2-Index"
  }, {
    path: "/products/fp/basic/flim/Index",
    component: _bffb47d4,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-flim-Index"
  }, {
    path: "/products/fp/basic/flim/Properties",
    component: _5a673fc2,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-flim-Properties"
  }, {
    path: "/products/fp/basic/photoactivatable/Index",
    component: _4489bac6,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-photoactivatable-Index"
  }, {
    path: "/products/fp/basic/photoactivatable/Properties",
    component: _7e84af6f,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-photoactivatable-Properties"
  }, {
    path: "/products/fp/basic/tag-fps/Index",
    component: _f76d1640,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-tag-fps-Index"
  }, {
    path: "/products/fp/basic/tag-fps/Properties",
    component: _603194d6,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-tag-fps-Properties"
  }, {
    path: "/products/fp/basic/turbo-colors/Index",
    component: _7f06006b,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-turbo-colors-Index"
  }, {
    path: "/products/fp/basic/turbo-colors/Properties",
    component: _497b28ea,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-turbo-colors-Properties"
  }, {
    path: "/products/fp/basic/whole-body-imaging/Index",
    component: _e3a3bda2,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-whole-body-imaging-Index"
  }, {
    path: "/products/fp/basic/whole-body-imaging/Properties",
    component: _5cdce2a6,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-whole-body-imaging-Properties"
  }, {
    path: "/products/fp/biosensors/case12/Citing",
    component: _8ab145c8,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-biosensors-case12-Citing"
  }, {
    path: "/products/fp/biosensors/case12/Index",
    component: _5127b78c,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-biosensors-case12-Index"
  }, {
    path: "/products/fp/biosensors/casper3-gr/Citing",
    component: _ea1b6c00,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-biosensors-casper3-gr-Citing"
  }, {
    path: "/products/fp/biosensors/casper3-gr/Index",
    component: _7543ea54,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-biosensors-casper3-gr-Index"
  }, {
    path: "/products/fp/biosensors/hyper/Citing",
    component: _6027f1bd,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-biosensors-hyper-Citing"
  }, {
    path: "/products/fp/biosensors/hyper/Index",
    component: _6925f779,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-biosensors-hyper-Index"
  }, {
    path: "/products/fp/photosensibilizators/arrest-red/Index",
    component: _72c7887c,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-photosensibilizators-arrest-red-Index"
  }, {
    path: "/products/fp/photosensibilizators/killer-orange/Citing",
    component: _5d56bd7a,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-photosensibilizators-killer-orange-Citing"
  }, {
    path: "/products/fp/photosensibilizators/killer-orange/Index",
    component: _43823f33,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-photosensibilizators-killer-orange-Index"
  }, {
    path: "/products/fp/photosensibilizators/killer-red/Citing",
    component: _45929354,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-photosensibilizators-killer-red-Citing"
  }, {
    path: "/products/fp/photosensibilizators/killer-red/Index",
    component: _58b571fc,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-photosensibilizators-killer-red-Index"
  }, {
    path: "/products/pcr/polymerases/encyclo/Citing",
    component: _9fb2a98c,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-polymerases-encyclo-Citing"
  }, {
    path: "/products/pcr/polymerases/encyclo/Index",
    component: _2048cb48,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-polymerases-encyclo-Index"
  }, {
    path: "/products/pcr/polymerases/hs-taq/Buffers",
    component: _80bdb0f8,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-polymerases-hs-taq-Buffers"
  }, {
    path: "/products/pcr/polymerases/hs-taq/Citing",
    component: _20d7a793,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-polymerases-hs-taq-Citing"
  }, {
    path: "/products/pcr/polymerases/hs-taq/Index",
    component: _6f5d2ee3,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-polymerases-hs-taq-Index"
  }, {
    path: "/products/pcr/polymerases/ktn-hs/Index",
    component: _008c8174,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-polymerases-ktn-hs-Index"
  }, {
    path: "/products/pcr/polymerases/rox/Index",
    component: _0f7bdb60,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-polymerases-rox-Index"
  }, {
    path: "/products/pcr/polymerases/snp-detect/Citing",
    component: _7a80eeda,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-polymerases-snp-detect-Citing"
  }, {
    path: "/products/pcr/polymerases/snp-detect/Index",
    component: _554b3a88,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-polymerases-snp-detect-Index"
  }, {
    path: "/products/pcr/polymerases/sybr/Index",
    component: _11a4d4b7,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-polymerases-sybr-Index"
  }, {
    path: "/products/pcr/polymerases/taq/Index",
    component: _575030d9,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-polymerases-taq-Index"
  }, {
    path: "/products/pcr/polymerases/tersus/Citing",
    component: _ca8cd7b6,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-polymerases-tersus-Citing"
  }, {
    path: "/products/pcr/polymerases/tersus/Index",
    component: _63bb2fde,
    pathToRegexpOptions: {"strict":true},
    name: "products-pcr-polymerases-tersus-Index"
  }, {
    path: "/products/fp/basic/flim/bruslee/Index",
    component: _1e5a03a5,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-flim-bruslee-Index"
  }, {
    path: "/products/fp/basic/flim/slim/Index",
    component: _72416dac,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-flim-slim-Index"
  }, {
    path: "/products/fp/basic/photoactivatable/kfp-red/Citing",
    component: _29a8cea6,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-photoactivatable-kfp-red-Citing"
  }, {
    path: "/products/fp/basic/photoactivatable/kfp-red/Index",
    component: _1d115970,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-photoactivatable-kfp-red-Index"
  }, {
    path: "/products/fp/basic/photoactivatable/pa-tag-rfp/Index",
    component: _2317d628,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-photoactivatable-pa-tag-rfp-Index"
  }, {
    path: "/products/fp/basic/photoactivatable/ps-cfp2/Citing",
    component: _2cc75f30,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-photoactivatable-ps-cfp2-Citing"
  }, {
    path: "/products/fp/basic/photoactivatable/ps-cfp2/Index",
    component: _56f98fa6,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-photoactivatable-ps-cfp2-Index"
  }, {
    path: "/products/fp/basic/tag-fps/fusion-red/Citing",
    component: _38527fc9,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-tag-fps-fusion-red-Citing"
  }, {
    path: "/products/fp/basic/tag-fps/fusion-red/Index",
    component: _bca91026,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-tag-fps-fusion-red-Index"
  }, {
    path: "/products/fp/basic/tag-fps/mkate2/Citing",
    component: _0b57cdd3,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-tag-fps-mkate2-Citing"
  }, {
    path: "/products/fp/basic/tag-fps/mkate2/Index",
    component: _3d1f40a3,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-tag-fps-mkate2-Index"
  }, {
    path: "/products/fp/basic/tag-fps/tag-bfp/Citing",
    component: _1dce61e8,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-tag-fps-tag-bfp-Citing"
  }, {
    path: "/products/fp/basic/tag-fps/tag-bfp/Index",
    component: _550cb44a,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-tag-fps-tag-bfp-Index"
  }, {
    path: "/products/fp/basic/tag-fps/tag-cfp/Citing",
    component: _5a12ceaa,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-tag-fps-tag-cfp-Citing"
  }, {
    path: "/products/fp/basic/tag-fps/tag-cfp/Index",
    component: _438fbacb,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-tag-fps-tag-cfp-Index"
  }, {
    path: "/products/fp/basic/tag-fps/tag-gfp2/Citing",
    component: _43cbc7dd,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-tag-fps-tag-gfp2-Citing"
  }, {
    path: "/products/fp/basic/tag-fps/tag-gfp2/Index",
    component: _16c2454e,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-tag-fps-tag-gfp2-Index"
  }, {
    path: "/products/fp/basic/tag-fps/tag-rfp/Citing",
    component: _0ef568fc,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-tag-fps-tag-rfp-Citing"
  }, {
    path: "/products/fp/basic/tag-fps/tag-rfp/Index",
    component: _3d3d1c5a,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-tag-fps-tag-rfp-Index"
  }, {
    path: "/products/fp/basic/tag-fps/tag-yfp/Citing",
    component: _3c05ec55,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-tag-fps-tag-yfp-Citing"
  }, {
    path: "/products/fp/basic/tag-fps/tag-yfp/Index",
    component: _7a5b6c3e,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-tag-fps-tag-yfp-Index"
  }, {
    path: "/products/fp/basic/turbo-colors/turbo-fp602/Citing",
    component: _7d7b795b,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-turbo-colors-turbo-fp602-Citing"
  }, {
    path: "/products/fp/basic/turbo-colors/turbo-fp602/Index",
    component: _5994041b,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-turbo-colors-turbo-fp602-Index"
  }, {
    path: "/products/fp/basic/turbo-colors/turbo-fp635/Citing",
    component: _60197cfb,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-turbo-colors-turbo-fp635-Citing"
  }, {
    path: "/products/fp/basic/turbo-colors/turbo-fp635/Index",
    component: _2f570c7b,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-turbo-colors-turbo-fp635-Index"
  }, {
    path: "/products/fp/basic/turbo-colors/turbo-gfp/Citing",
    component: _007d1538,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-turbo-colors-turbo-gfp-Citing"
  }, {
    path: "/products/fp/basic/turbo-colors/turbo-gfp/Index",
    component: _2b9e0ec4,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-turbo-colors-turbo-gfp-Index"
  }, {
    path: "/products/fp/basic/turbo-colors/turbo-rfp/Citing",
    component: _95f681e6,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-turbo-colors-turbo-rfp-Citing"
  }, {
    path: "/products/fp/basic/turbo-colors/turbo-rfp/Index",
    component: _29d24029,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-turbo-colors-turbo-rfp-Index"
  }, {
    path: "/products/fp/basic/turbo-colors/turbo-yfp/Citing",
    component: _3bd57b34,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-turbo-colors-turbo-yfp-Citing"
  }, {
    path: "/products/fp/basic/turbo-colors/turbo-yfp/Index",
    component: _a13124a0,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-turbo-colors-turbo-yfp-Index"
  }, {
    path: "/products/fp/basic/whole-body-imaging/katushka2s/Citing",
    component: _05d136bd,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-whole-body-imaging-katushka2s-Citing"
  }, {
    path: "/products/fp/basic/whole-body-imaging/katushka2s/Index",
    component: _76c01279,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-whole-body-imaging-katushka2s-Index"
  }, {
    path: "/products/fp/basic/whole-body-imaging/nirfp/Citing",
    component: _4039fc35,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-whole-body-imaging-nirfp-Citing"
  }, {
    path: "/products/fp/basic/whole-body-imaging/nirfp/Index",
    component: _bc2683fe,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-whole-body-imaging-nirfp-Index"
  }, {
    path: "/products/fp/basic/whole-body-imaging/turbo-fp650/Citing",
    component: _e0fce644,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-whole-body-imaging-turbo-fp650-Citing"
  }, {
    path: "/products/fp/basic/whole-body-imaging/turbo-fp650/Index",
    component: _32e81790,
    pathToRegexpOptions: {"strict":true},
    name: "products-fp-basic-whole-body-imaging-turbo-fp650-Index"
  }, {
    path: "/",
    component: _49decfed,
    children: [{
      path: "",
      component: _59a7c9bc
    }]
  }, {
    path: "/about",
    component: _46183b47,
    children: [{
      path: "",
      component: _1e545bc0
    }, {
      path: "contacts",
      component: _1a098c45
    }, {
      path: "news",
      component: _49d77fc5
    }, {
      path: "news/all",
      component: _0cdeaf9c
    }, {
      path: "vacancy",
      component: _a7c86b6e
    }]
  }, {
    path: "/auth",
    component: _46183b47,
    children: [{
      path: "login",
      component: _2e89e7b2
    }, {
      path: "registration",
      component: _b7228760
    }, {
      path: "registration-complete",
      component: _6515fcae
    }, {
      path: "restore-password",
      component: _2fd77452
    }, {
      path: "set-new-password",
      component: _69322c70
    }, {
      path: "logout",
      component: _ba99b33e
    }]
  }, {
    path: "/basket",
    component: _46183b47,
    children: [{
      path: "",
      component: _beb76a8e
    }]
  }, {
    path: "/order",
    component: _46183b47,
    children: [{
      path: "",
      component: _286f6f98
    }, {
      path: "shipping",
      component: _582f6202
    }, {
      path: "distributors",
      component: _9a6a7386
    }, {
      path: "return",
      component: _5f99cec1
    }, {
      path: "pay",
      component: _7e7df20a
    }, {
      path: "sample-requirements",
      component: _d99df12a
    }, {
      path: "special-offers",
      component: _5ac02e02
    }]
  }, {
    path: "/payment/sberbank-complete",
    component: _46183b47,
    children: [{
      path: "",
      component: _5d2f6234
    }]
  }, {
    path: "/policy",
    component: _49decfed,
    children: [{
      path: "",
      component: _d9e215b6
    }]
  }, {
    path: "/products",
    component: _46183b47,
    children: [{
      path: "",
      component: _95dbe052
    }, {
      path: "contacts",
      component: _952f235a
    }, {
      path: "pricelist",
      component: _6ce871ec
    }, {
      path: "antibodies",
      component: _43077080
    }, {
      path: "antibodies/citing",
      component: _32df09b4
    }, {
      path: "antibodies/anti-gfp",
      component: _3e3f2b52
    }, {
      path: "antibodies/anti-tag-cgyfp",
      component: _3ece5084
    }, {
      path: "antibodies/anti-trfp",
      component: _3a89c9be
    }, {
      path: "antibodies/anti-turbo-gfp",
      component: _65e03350
    }, {
      path: "antibodies/anti-turbo-yfp",
      component: _1721cf1e
    }, {
      path: "antibodies/anti-killer-red",
      component: _e9686fe6
    }, {
      path: "cdna",
      component: _7563acb2
    }, {
      path: "cdna/race",
      component: _4c559818
    }, {
      path: "cdna/race/mint-race-cdna",
      component: _0a8df536
    }, {
      path: "cdna/race/mint-race-primers",
      component: _af1fb062
    }, {
      path: "cdna/normalization",
      component: _615f0dc0
    }, {
      path: "cdna/normalization/dsn",
      component: _8e94d320
    }, {
      path: "cdna/normalization/dsn/citing",
      component: _53810276
    }, {
      path: "cdna/normalization/trimmer2",
      component: _0ec0adcf
    }, {
      path: "cdna/normalization/trimmer2/citing",
      component: _824e5212
    }, {
      path: "cdna/synthesis",
      component: _1c6f5677
    }, {
      path: "cdna/synthesis/mmlv",
      component: _61407e76
    }, {
      path: "cdna/synthesis/mmlv/citing",
      component: _660e0179
    }, {
      path: "cdna/synthesis/mint",
      component: _613fa3c6
    }, {
      path: "cdna/synthesis/mint/citing",
      component: _c725e65e
    }, {
      path: "cdna/synthesis/onetube",
      component: _09415322
    }, {
      path: "cdna/synthesis/onetube/citing",
      component: _782d2fa3
    }, {
      path: "cdna/synthesis/magnus",
      component: _77992854
    }, {
      path: "components",
      component: _12f20834
    }, {
      path: "cloning",
      component: _7da6293c
    }, {
      path: "cloning/vectors",
      component: _6c24b3c0
    }, {
      path: "cloning/quick-ta",
      component: _45ee706c
    }, {
      path: "cloning/competent-cells",
      component: _2a7f9584
    }, {
      path: "cloning/competent-cells/citing",
      component: _1af6e5e2
    }, {
      path: "practicum",
      component: _5f75a9ca
    }, {
      path: "practicum/topics",
      component: _bbcf11a8
    }, {
      path: "evaluation",
      component: _1779504c
    }, {
      path: "evaluation/ladders",
      component: _43c49d1c
    }, {
      path: "evaluation/quantum",
      component: _4a6d7034
    }, {
      path: "evaluation/quantum/concentration",
      component: _25c1a0fe
    }, {
      path: "evaluation/quantum/fragmentation",
      component: _5af9d48e
    }, {
      path: "fp",
      component: _333165e8
    }, {
      path: "fp/basic",
      component: _ffbb3772
    }, {
      path: "fp/basic/tag-fps",
      component: _309fa82e
    }, {
      path: "fp/basic/tag-fps/properties",
      component: _0dc37152
    }, {
      path: "fp/basic/tag-fps/tag-bfp",
      component: _6f4c32d0
    }, {
      path: "fp/basic/tag-fps/tag-bfp/citing",
      component: _3866b74e
    }, {
      path: "fp/basic/tag-fps/tag-cfp",
      component: _5c9e5599
    }, {
      path: "fp/basic/tag-fps/tag-cfp/citing",
      component: _2cb0286d
    }, {
      path: "fp/basic/tag-fps/tag-gfp2",
      component: _78a1f41b
    }, {
      path: "fp/basic/tag-fps/tag-gfp2/citing",
      component: _ddbf4baa
    }, {
      path: "fp/basic/tag-fps/tag-yfp",
      component: _1a7fdfaf
    }, {
      path: "fp/basic/tag-fps/tag-yfp/citing",
      component: _2affe117
    }, {
      path: "fp/basic/tag-fps/tag-rfp",
      component: _0ca0d6a8
    }, {
      path: "fp/basic/tag-fps/tag-rfp/citing",
      component: _7cfdc93e
    }, {
      path: "fp/basic/tag-fps/mkate2",
      component: _3b89fae5
    }, {
      path: "fp/basic/tag-fps/mkate2/citing",
      component: _2b392ca1
    }, {
      path: "fp/basic/tag-fps/fusion-red",
      component: _1b9e2a2f
    }, {
      path: "fp/basic/tag-fps/fusion-red/citing",
      component: _4daae697
    }, {
      path: "fp/basic/turbo-colors",
      component: _47cfed2d
    }, {
      path: "fp/basic/turbo-colors/properties",
      component: _c6c76c90
    }, {
      path: "fp/basic/turbo-colors/turbo-gfp",
      component: _6904ee60
    }, {
      path: "fp/basic/turbo-colors/turbo-gfp/citing",
      component: _a5c6aef4
    }, {
      path: "fp/basic/turbo-colors/turbo-yfp",
      component: _5456871c
    }, {
      path: "fp/basic/turbo-colors/turbo-yfp/citing",
      component: _4b72c698
    }, {
      path: "fp/basic/turbo-colors/turbo-rfp",
      component: _7014992a
    }, {
      path: "fp/basic/turbo-colors/turbo-rfp/citing",
      component: _2c4484db
    }, {
      path: "fp/basic/turbo-colors/turbo-fp602",
      component: _57995c46
    }, {
      path: "fp/basic/turbo-colors/turbo-fp602/citing",
      component: _b08a96ae
    }, {
      path: "fp/basic/turbo-colors/turbo-fp635",
      component: _aaeceb86
    }, {
      path: "fp/basic/turbo-colors/turbo-fp635/citing",
      component: _c7a8ef6e
    }, {
      path: "fp/basic/whole-body-imaging",
      component: _70dbc471
    }, {
      path: "fp/basic/whole-body-imaging/properties",
      component: _8f2f4618
    }, {
      path: "fp/basic/whole-body-imaging/katushka2s",
      component: _47102c72
    }, {
      path: "fp/basic/whole-body-imaging/katushka2s/citing",
      component: _afedcc02
    }, {
      path: "fp/basic/whole-body-imaging/turbo-fp650",
      component: _70c8660c
    }, {
      path: "fp/basic/whole-body-imaging/turbo-fp650/citing",
      component: _21619d2c
    }, {
      path: "fp/basic/whole-body-imaging/nirfp",
      component: _9fde227a
    }, {
      path: "fp/basic/whole-body-imaging/nirfp/citing",
      component: _4790b483
    }, {
      path: "fp/basic/photoactivatable",
      component: _25469008
    }, {
      path: "fp/basic/photoactivatable/properties",
      component: _c09f9086
    }, {
      path: "fp/basic/photoactivatable/ps-cfp2",
      component: _34ab4a30
    }, {
      path: "fp/basic/photoactivatable/ps-cfp2/citing",
      component: _75b66604
    }, {
      path: "fp/basic/photoactivatable/kfp-red",
      component: _1af299b2
    }, {
      path: "fp/basic/photoactivatable/kfp-red/citing",
      component: _38e46774
    }, {
      path: "fp/basic/photoactivatable/pa-tag-rfp",
      component: _68f63f3a
    }, {
      path: "fp/basic/flim",
      component: _1ceb5058
    }, {
      path: "fp/basic/flim/bruslee",
      component: _40ca9d67
    }, {
      path: "fp/basic/flim/slim",
      component: _7993057a
    }, {
      path: "fp/basic/previous",
      component: _60d8613c
    }, {
      path: "fp/biosensors",
      component: _71f1081e
    }, {
      path: "fp/biosensors/products-list",
      component: _60db3756
    }, {
      path: "fp/biosensors/hyper",
      component: _6f1b49bb
    }, {
      path: "fp/biosensors/hyper/citing",
      component: _69d1b88b
    }, {
      path: "fp/biosensors/case12",
      component: _4564b188
    }, {
      path: "fp/biosensors/case12/citing",
      component: _5cb54a5e
    }, {
      path: "fp/biosensors/casper3-gr",
      component: _0a946324
    }, {
      path: "fp/biosensors/casper3-gr/citing",
      component: _3d7bcc42
    }, {
      path: "fp/photosensibilizators",
      component: _3d018894
    }, {
      path: "fp/photosensibilizators/products-list",
      component: _7c056620
    }, {
      path: "fp/photosensibilizators/killer-red",
      component: _de792460
    }, {
      path: "fp/photosensibilizators/killer-red/citing",
      component: _7d2e1716
    }, {
      path: "fp/photosensibilizators/arrest-red",
      component: _44e57c4a
    }, {
      path: "fp/photosensibilizators/killer-orange",
      component: _5317dbf5
    }, {
      path: "fp/photosensibilizators/killer-orange/citing",
      component: _05676d91
    }, {
      path: "fp/recombinants",
      component: _3185c91c
    }, {
      path: "fp/vectors",
      component: _56e9f0a9
    }, {
      path: "fp/vectors/organelle-labeling",
      component: _21e092d6
    }, {
      path: "fp/vectors/promoter-testing",
      component: _0e6007d6
    }, {
      path: "isolation",
      component: _63b5d1d4
    }, {
      path: "isolation/plasmid-kits",
      component: _b0c179ca
    }, {
      path: "isolation/extract-dna-ffpe",
      component: _08e39232
    }, {
      path: "isolation/extract-dna-blood-and-cells",
      component: _2ec4a3e2
    }, {
      path: "isolation/rna-solo",
      component: _7cc33168
    }, {
      path: "isolation/bisquick",
      component: _f1dd8906
    }, {
      path: "isolation/cleanup",
      component: _cf0e7830
    }, {
      path: "isolation/cleanup-pcr",
      component: _4434fbb6
    }, {
      path: "isolation/cleanup-gel",
      component: _4434de25
    }, {
      path: "isolation/cleanmag",
      component: _3a3876e9
    }, {
      path: "isolation/cleanmag/magstand",
      component: _24c9f0cc
    }, {
      path: "isolation/intact-rna",
      component: _80d406b4
    }, {
      path: "isolation/ribocare-rnase-inhibitor",
      component: _2c9cdd9e
    }, {
      path: "isolation/dnase-e",
      component: _27ba2c10
    }, {
      path: "isolation/extract-rna",
      component: _a8a034c0
    }, {
      path: "isolation/clean-rna",
      component: _769f35d8
    }, {
      path: "isolation/rcf-rpm",
      component: _6a32a19b
    }, {
      path: "lenti",
      component: _2a3af5dc
    }, {
      path: "mycoplasma",
      component: _06ca3478
    }, {
      path: "mycoplasma/myco-report",
      component: _63498af4
    }, {
      path: "mycoplasma/myco-real-time",
      component: _eab40b46
    }, {
      path: "nomotech",
      component: _a7c38c52
    }, {
      path: "nomotech/insider",
      component: _1e4c13bb
    }, {
      path: "nomotech/insider-fullras",
      component: _50c3cada
    }, {
      path: "nomotech/insider-egfr",
      component: _2e762f29
    }, {
      path: "nomotech/BRCA",
      component: _269202e9
    }, {
      path: "nomotech/CYP2C9-VKORC1-CYP4F2",
      component: _0fb054e9
    }, {
      path: "nomotech/CYP2C19",
      component: _89eb9c90
    }, {
      path: "nomotech/SLCO1B1",
      component: _1ad6bd40
    }, {
      path: "nomotech/CES1",
      component: _2692485b
    }, {
      path: "nomotech/F2-F5",
      component: _a8504ad6
    }, {
      path: "nomotech/NAT2",
      component: _2697398c
    }, {
      path: "nomotech/MGMT",
      component: _2696dafc
    }, {
      path: "nomotech/extract-dna2",
      component: _021f793e
    }, {
      path: "nomotech/ffpe",
      component: _62a5039f
    }, {
      path: "pcr",
      component: _61170827
    }, {
      path: "pcr/bulk",
      component: _e88969c0
    }, {
      path: "pcr/polymerases",
      component: _4c1f3974
    }, {
      path: "pcr/polymerases/citing",
      component: _599fd060
    }, {
      path: "pcr/polymerases/encyclo",
      component: _7062191e
    }, {
      path: "pcr/polymerases/encyclo/citing",
      component: _dd3654f0
    }, {
      path: "pcr/polymerases/tersus",
      component: _0a7f5142
    }, {
      path: "pcr/polymerases/tersus/citing",
      component: _52ce5f67
    }, {
      path: "pcr/polymerases/snp-detect",
      component: _d29586ec
    }, {
      path: "pcr/polymerases/snp-detect/citing",
      component: _35761f9c
    }, {
      path: "pcr/polymerases/ktn-hs",
      component: _66c6cb7c
    }, {
      path: "pcr/polymerases/taq",
      component: _69cd99ca
    }, {
      path: "pcr/polymerases/taq/buffers",
      component: _c365f25c
    }, {
      path: "pcr/polymerases/hs-taq",
      component: _0ff9519e
    }, {
      path: "pcr/polymerases/hs-taq/buffers",
      component: _c365f25c
    }, {
      path: "pcr/polymerases/hs-taq/citing",
      component: _04294c56
    }, {
      path: "pcr/polymerases/sybr",
      component: _1ddb2885
    }, {
      path: "pcr/polymerases/rox",
      component: _79a72fdc
    }, {
      path: "pcr/mixes",
      component: _40c37e22
    }, {
      path: "pcr/mixes/citing",
      component: _659fddf8
    }, {
      path: "pcr/mixes/screenmix",
      component: _5071b3a0
    }, {
      path: "pcr/mixes/screenmix-hs",
      component: _0a59566a
    }, {
      path: "pcr/mixes/screenmix-hs-udg",
      component: _524c8226
    }, {
      path: "pcr/mixes/qpcrmix-hs",
      component: _13c3e82e
    }, {
      path: "pcr/mixes/qpcrmix-hs/citing",
      component: _2bef3cc6
    }, {
      path: "pcr/mixes/qpcrmix-hs-udg",
      component: _1ae569e2
    }, {
      path: "pcr/mixes/qpcrmix-hs-sybr",
      component: _5f1b7cbf
    }, {
      path: "pcr/mixes/qpcrmix-hs-sybr-rox",
      component: _8089cec8
    }, {
      path: "pcr/mixes/qpcrmix-hs-rox",
      component: _1ae57d9c
    }, {
      path: "pcr/mixes/ktnmix-hs",
      component: _8ad79fdc
    }]
  }, {
    path: "/profile",
    component: _46183b47,
    children: [{
      path: "",
      component: _15e15188
    }, {
      path: "addresses",
      component: _0743650c
    }, {
      path: "contacts",
      component: _889abcae
    }, {
      path: "discount",
      component: _246492d2
    }, {
      path: "orders",
      component: _915dfc8a
    }]
  }, {
    path: "/services",
    component: _46183b47,
    children: [{
      path: "",
      component: _438aab51
    }, {
      path: "chemistry",
      component: _ebdd4934
    }, {
      path: "chemistry/contacts",
      component: _eb308c3c
    }, {
      path: "chemistry/organic-synthesis",
      component: _62b26949
    }, {
      path: "chemistry/development",
      component: _7205d88e
    }, {
      path: "synthesis",
      component: _567bda56
    }, {
      path: "synthesis/kination",
      component: _288955c8
    }, {
      path: "synthesis/citing",
      component: _6e833b50
    }, {
      path: "synthesis/order-form",
      component: _82b1fe94
    }, {
      path: "synthesis/contacts",
      component: _56d238d2
    }, {
      path: "sequencing/sanger",
      component: _34066567
    }, {
      path: "sequencing/sanger/contacts",
      component: _345cc3e3
    }, {
      path: "sequencing/sanger/citing",
      component: _424a125f
    }, {
      path: "sequencing/sanger/requirements",
      component: _48c9e34a
    }, {
      path: "sequencing/sanger/standard-primers",
      component: _b1358450
    }, {
      path: "ges",
      component: _50c20497
    }, {
      path: "ges/contacts",
      component: _51186313
    }, {
      path: "ges/gene-synthesis",
      component: _25167d8c
    }, {
      path: "ges/gene-synthesis/citing",
      component: _92b19e28
    }, {
      path: "ges/mutagenesis",
      component: _6dd7c1fb
    }, {
      path: "ges/mutagenesis/citing",
      component: _42a4484b
    }, {
      path: "ges/pcr-cloning",
      component: _5807d534
    }, {
      path: "ges/amplicon-cloning",
      component: _2e200ecc
    }, {
      path: "pricelist",
      component: _e35a7f34
    }]
  }, {
    path: "/sout",
    component: _46183b47,
    children: [{
      path: "ru",
      component: _6901c567
    }, {
      path: "lab",
      component: _9192622e
    }, {
      path: "evrogen",
      component: _4292d53e
    }, {
      path: "ip",
      component: _6901c44b
    }]
  }, {
    path: "/search",
    component: _46183b47,
    children: [{
      path: "",
      component: _0d2b051b
    }]
  }, {
    path: "/support",
    component: _46183b47,
    children: [{
      path: "",
      component: _0c60ce3c
    }, {
      path: "publications",
      component: _0f1901b7
    }, {
      path: "citing",
      component: _35a74e44
    }, {
      path: "technologies",
      component: _177aa09a
    }, {
      path: "technologies/normalization",
      component: _396a28a8
    }, {
      path: "technologies/genome-walking",
      component: _35af2f8d
    }, {
      path: "technologies/mint-cdna-synthesis",
      component: _6a38d4a9
    }, {
      path: "technologies/mos",
      component: _5811f048
    }, {
      path: "technologies/long-product-amplification",
      component: _6effa038
    }, {
      path: "technologies/smart",
      component: _9a921858
    }, {
      path: "technologies/step-out-race",
      component: _0359149e
    }, {
      path: "technologies/ssh",
      component: _5811ba5a
    }, {
      path: "technologies/wtb-pcr",
      component: _a944fe62
    }, {
      path: "technologies/rna-electrophoresis",
      component: _7a00aa2f
    }, {
      path: "technologies/rna-isolation",
      component: _66b98384
    }, {
      path: "technologies/plasmid-purification",
      component: _4b5f5236
    }, {
      path: "technologies/blood-preparation",
      component: _72946552
    }, {
      path: "license-statements",
      component: _d9ecbfd6
    }, {
      path: "license-statements/evrogen-fp-license",
      component: _1278f238
    }, {
      path: "license-statements/evrogen-dsn-license",
      component: _e4508756
    }, {
      path: "license-statements/invitrogen-gateway-license",
      component: _62e04d3a
    }, {
      path: "literature",
      component: _22bfef41
    }]
  }, {
    path: "/sequences",
    component: _ff348718
  }, {
    path: "/services/sequencing/sanger/order-form",
    component: _037959a8
  }, {
    path: "/payment-redirect/:uid",
    component: _8d461788,
    props: true
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
